import { FC, Fragment, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import MediVahanLogo from "./../../images/medivahan-logo.svg";
import Dashboard from "./../../images/Dashboard.svg";
import PrimaryPrescriptions from "./../../images/Primary-Prescriptions.svg";
import PrimaryDoctors from "./../../images/Primary-Doctors.svg";
import DoctorFace from "./../../images/doctor-face.svg";
import PrimaryFinancial from "./../../images/Primary-Financial.svg";
import PrimaryDelivery from "./../../images/Primary-Delivery.svg";
import SecondaryAdmin from "./../../images/Secondary-Admin.svg";
import RightArrow from "./../../images/Right-arrow.svg";
import { useDisclosure } from "../../hooks";
import { AuthPages, MainPages } from "../../Router";
import { useDispatch, useSelector } from "react-redux";
import { clearToken } from "../../redux/userSlice";
import { ResetPasswordModal } from "../ResetPasswordModal";
import { RootState } from "../../redux/store";

const MenuDropDown = ({ item }: any) => {
  const { subMenus, title, image } = item;

  const [isSubmenuOpen, setIsSubmenuOpen] = useState(false);
  const location = useLocation();
  const toggleSubmenu = () => {
    setIsSubmenuOpen((prev) => !prev);
  };

  const isActive = isSubmenuOpen;

  return (
    <Fragment>
      <button
        onClick={toggleSubmenu}
        className={`flex gap-4 w-full items-center justify-between p-4 text-primary-black text-base border-b font-normal
          ${
            isActive
              ? "font-semibold border-b-2 border-success"
              : "font-normal border-transparent"
          }`}
      >
        <div className="flex gap-4 items-center">
          <img
            src={image}
            alt={title}
            className={`${isActive ? "filter-red" : ""}`}
          />
          <span className="">{title}</span>
        </div>
        <span>
          <img
            src={RightArrow}
            alt="Right-arrow"
            className={`w-2 brightness-0 transition-transform duration-300 ${
              isSubmenuOpen ? "rotate-90 brightness-100" : "rotate-0"
            }`}
          />
        </span>
      </button>
      {isSubmenuOpen && (
        <ul className="flex flex-col gap-3 ps-9 pe-5 pt-3">
          {subMenus?.map((subMenu: any, index: number) => {
            const isSubMenuActive = location.pathname === subMenu?.link;

            return (
              <li>
                <Link
                  key={index}
                  to={subMenu?.link}
                  className={`flex gap-4 items-center text-primary-black text-base   ${
                    isSubMenuActive
                      ? "font-semibold"
                      : "font-normal border-transparent"
                  }`}
                >
                  <span>
                    <img
                      src={subMenu?.image}
                      alt={subMenu?.title}
                      className={`w-5 h-5 ${
                        isSubMenuActive ? "filter-red" : ""
                      }`}
                    />
                  </span>
                  {subMenu?.title}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </Fragment>
  );
};

export const Sidebar: FC = () => {
  const dispatch = useDispatch();
  const adminDetails = useSelector(
    (state: RootState) => state.user?.currentUser
  );

  const [isAdminOpen, AdminOpenHandler] = useDisclosure(false);
  const [isResertPasswordModalOpen, resertPasswordModalHandler] =
    useDisclosure(false);

  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    dispatch(clearToken({}));
    navigate(AuthPages.LOGIN);
  };

  const menuData = [
    { id: 1, link: "/", image: Dashboard, title: "Dashboard" },
    {
      id: 2,
      link: MainPages.Prescriptions,
      image: PrimaryPrescriptions,
      title: "Prescriptions",
    },
    { id: 3, link: MainPages.Doctor, image: PrimaryDoctors, title: "Doctors" },
    {
      id: 4,
      link: MainPages.Financial,
      image: PrimaryFinancial,
      title: "Financial",
      subMenus: [
        {
          link: MainPages.Financial,
          image: PrimaryPrescriptions,
          title: "Prescriptions",
        },
        {
          link: MainPages.FinancialDoctors,
          image: PrimaryDoctors,
          title: "Doctor",
        },
        {
          link: MainPages.NewDoctor,
          image: DoctorFace,
          title: "New Doctor",
        },
      ],
    },
    {
      id: 5,
      link: MainPages.Delivery,
      image: PrimaryDelivery,
      title: "Delivery",
    },
  ];

  return (
    <>
      <div className="h-full basis-[256px] p-4 pe-0">
        <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl bg-white overflow-hidden h-full flex flex-col gap-10">
          <div
            className="shadow-[0_0_8px_0_#40515214] py-3"
            onClick={() => navigate(MainPages.ONBOARD)}
          >
            <img
              src={MediVahanLogo}
              alt="MediVahan-logo"
              className="w-full max-w-[178px] mx-auto"
            />
          </div>
          <div className="flex-1 flex flex-col justify-between overflow-y-auto">
            <ul className="flex flex-col gap-2">
              {menuData.map((item) => (
                <li key={item.id}>
                  {item?.subMenus ? (
                    <MenuDropDown item={item} />
                  ) : (
                    <Link
                      to={item.link}
                      className={`flex gap-4 items-center p-4 text-primary-black text-base border-b
                      ${
                        location.pathname === item.link
                          ? "font-semibold border-b-2 border-success"
                          : "font-normal border-transparent"
                      }`}
                    >
                      <span>
                        <img
                          src={item.image}
                          alt={item.title}
                          className={`transition-colors duration-200 ${
                            location.pathname === item.link ? "filter-red" : ""
                          }`}
                        />
                      </span>
                      {item.title}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
            <div className="">
              <ul>
                <li>
                  <div className="relative">
                    <button
                      type="button"
                      onClick={AdminOpenHandler.toggle}
                      className="flex gap-4 w-full items-center justify-between p-4 text-primary-black font-semibold text-base"
                    >
                      <div className="flex gap-4 items-center">
                        <span>
                          <img src={SecondaryAdmin} alt="Admin" />
                        </span>
                        Admin
                      </div>
                      <span
                        className={`mt-1  ${isAdminOpen ? "-rotate-90 " : " "}`}
                      >
                        <img
                          src={RightArrow}
                          alt="Right-arrow"
                          className="w-2"
                        />
                      </span>
                    </button>
                    {isAdminOpen && (
                      <div className="shadow-[0_-8px_16px_0_#0000000A] flex flex-col gap-4 rounded-t-3xl bg-white py-6 px-4 absolute bottom-full w-full left-0 border-b border-gray">
                        <div className="flex flex-col gap-2">
                          <span className="block text-sm font-normal text-dark-black">
                            Admin ID
                          </span>
                          <p className="text-sm font-medium text-primary-black truncate">
                            {adminDetails?.username}
                          </p>
                        </div>
                        <div className="text-center">
                          <button
                            type="button"
                            onClick={resertPasswordModalHandler.open}
                            className="uppercase underline underline-offset-1 text-success text-sm font-bold"
                          >
                            Reset Password
                          </button>
                        </div>
                        <div className="text-center">
                          <button
                            type="button"
                            onClick={handleLogout}
                            className="uppercase underline underline-offset-1 text-success text-sm font-bold"
                          >
                            Logout
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </li>
              </ul>
              <ResetPasswordModal
                isResertPasswordModalOpen={isResertPasswordModalOpen}
                resertPasswordModalHandler={resertPasswordModalHandler}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="h-screen fixed p-4 top-0 right-0 z-50 w-full max-w-[calc(100%-256px)] rounded-2xl">
                <div className="h-full backdrop-blur-[60px] bg-white_054 rounded-2xl overflow-hidden flex flex-col justify-center items-center">
                    <div className="w-full max-w-[648px] mx-auto">
                        <div className="flex flex-col gap-12">
                            <div className="flex flex-col gap-4">
                                <h1 className="text-[40px] font-bold text-primary-black">Authorization Require</h1>
                                <p className="text-base font-normal text-dark-black">To access need authorization through register email and otp.</p>
                            </div>
                            <form action="" className="flex flex-col gap-12">
                                <div className="flex flex-col">
                                    <label className="block text-dark-black text-sm font-normal p-2 pb-0">Register Email <span className="text-success">*</span></label>
                                    <input type="email" placeholder="Enter Email" className="text-base font-semibold text-primary-black placeholder:text-primary-black outline-none border-b border-gray p-2" />
                                </div>
                                <div className="">
                                    <button type="button" className="text-base uppercase font-bold text-white p-3 w-full rounded-3xl bg-success">Continue</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}
      {/* <div className="h-screen fixed p-4 top-0 right-0 z-50 w-full max-w-[calc(100%-256px)] rounded-2xl">
                <div className="h-full backdrop-blur-[60px] bg-white_054 rounded-2xl overflow-hidden flex flex-col justify-center items-center">
                    <div className="w-full max-w-[648px] mx-auto">
                        <div className="flex flex-col gap-12">
                            <div className="flex flex-col gap-4">
                                <h1 className="text-[40px] font-bold text-primary-black">OTP Verification</h1>
                                <p className="text-base font-normal text-dark-black">OTP sent to given email address</p>
                            </div>
                            <form action="" className="flex flex-col gap-12">
                                <div className="flex flex-col">
                                    <label className="block text-dark-black text-sm font-normal p-2 pb-0">Enter OTP <span className="text-success">*</span></label>
                                    <OTPVerification />
                                </div>
                                <div className="">
                                    <button type="button" className="text-base uppercase font-bold text-white p-3 w-full rounded-3xl bg-success">Verify</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
};
