import { Priority } from "../constants/types";

export const localStorageKeys = {
  AUTH_TOKEN: "auth_token",
};

export const statusData = [
  { title: "Open", color: "blue", bgColor: "unnamed-a55cff29", status: "open" },
  {
    title: "Preparing",
    color: "yellow",
    bgColor: "unnamed-ebbb1429",
    status: "preparing",
  },
  {
    title: "Decline",
    color: "red",
    bgColor: "unnamed-f54e4e29",
    status: "declined",
  },
  {
    title: "Dispatch",
    color: "unnamed-0BC1E5",
    bgColor: "unnamed-0bc1e529",
    status: "dispatch",
  },
  {
    title: "Delivered",
    color: "green",
    bgColor: "unnamed-3fc27b29",
    status: "delivered",
  },
  {
    title: "Return",
    color: "unnamed-F54E8E",
    bgColor: "unnamed-f54e8e29",
    status: "return",
  },
  {
    title: "Closed",
    color: "primary-blue",
    bgColor: "unnamed-4a74f229",
    status: "closed",
  },
];

export const priorityColors: Record<Priority, string> = {
  high: "yellow",
  low: "green",
  urgent: "red",
  medium: "blue",
};
