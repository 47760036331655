import clsx from "clsx";
import { ButtonHTMLAttributes, FC } from "react";

interface IChipWithSelectProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  selected?: boolean;
  color: string;
}

export const ChipWithSelect: FC<IChipWithSelectProps> = ({
  title,
  color,
  selected = true,
  ...props
}) => {
  return (
    <button
      type="button"
      className={clsx(
        `flex items-center gap-2 border text-sm font-semibold text-dark-black capitalize rounded-[30px] py-2.5 px-4 ${
          selected ? `border-${color}` : "border-gray"
        }`
      )}
      {...props}
    >
      <div
        className={`w-4 min-w-4 h-4 flex items-center justify-center rounded-full border border-${color} ${
          selected && `bg-${color}`
        }`}
      >
        {selected ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
          >
            <path
              d="M0.998047 3.3849L3.08805 6.0108C3.2574 6.22357 3.56952 6.2533 3.776 6.07632L8.99805 1.60028"
              stroke="white"
              stroke-width="1.6"
              stroke-linecap="round"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="7"
            viewBox="0 0 10 7"
            fill="none"
          >
            <path
              d="M0.998047 3.3849L3.08805 6.0108C3.2574 6.22357 3.56952 6.2533 3.776 6.07632L8.99805 1.60028"
              stroke="white"
              stroke-width="1.6"
              stroke-linecap="round"
            />
          </svg>
        )}
      </div>
      {title}
    </button>
  );
};
