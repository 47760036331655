export const objectToQueryString = (params: Record<string, any>): string => {
  return Object.keys(params)
    .filter((key) => params[key] !== null && params[key] !== undefined) // Remove null and undefined values
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");
};

export const currencyFormatter = new Intl.NumberFormat("en-IN", {
  style: "currency",
  currency: "INR",
});
