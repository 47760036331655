import { FormikProps } from "formik";
import { FC } from "react";

interface IRadioGroupProps {
  name: string;
  label?: string;
  options: { value: string; label: string }[];
  formik: FormikProps<any>;
  labelClassName?: string;
  radioClassName?: string;
}

export const RadioGroup: FC<IRadioGroupProps> = ({
  name,
  label,
  options,
  formik,
  labelClassName = "",
  radioClassName = "",
}) => {
  const { handleChange, values, errors, touched } = formik;

  return (
    <div>
      {label && (
        <label
          className={`text-dark-black text-sm font-normal block my-2 ms-2 ${labelClassName}`}
        >
          {label}
          <span className="text-success">*</span>
        </label>
      )}
      <div className="flex flex-col gap-4">
        {options.map((option, index) => (
          <div className="form-group" key={index}>
            <input
              type="radio"
              id={option.value}
              name={name}
              value={option.value}
              checked={values[name] === option.value}
              onChange={handleChange}
              className="hidden"
            />
            <label
              htmlFor={option.value}
              className={`text-base font-medium text-primary-black flex items-center gap-2 cursor-pointer ${radioClassName}`}
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
      {errors[name] && touched[name] && (
        <span className="text-red block mt-1 text-sm">
          {errors[name] as string}
        </span>
      )}
    </div>
  );
};
