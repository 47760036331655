import { FC, useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GetPrescriptionDetails } from "../../utils/query";
import { useParams } from "react-router-dom";
import {
  BillDetails,
  DeliveryBoyDetails,
  PatientDetails,
  PrescriptionDetails,
} from "../../components/prescriptions";
import { FormikProps, useFormik } from "formik";
import { PrescriptionDetailFormValuesType } from "../../constants/types";
import { PrescriptionDetailSchema } from "../../constants/schema";
import { PrescriptionDetailValues } from "../../constants/data";
import { updatePrescriptionDetails } from "../../utils/mutations";
import { useLoading } from "../../context/LoadingContext";
import { Button } from "../../components";
import { toast } from "react-toastify";

export const PrescriptionsDetails: FC = () => {
  const { pid } = useParams();
  const [isEditable, setIsEditable] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const { showLoading, hideLoading } = useLoading();

  const {
    data: prescriptionData,
    isLoading: isLoadingPrescription,
    isFetching: isFetchingPrescription,
    refetch,
  } = useQuery({
    queryKey: ["prescription-details"],
    queryFn: () => GetPrescriptionDetails(parseInt(pid as string)),
    enabled: false,
  });

  useEffect(() => {
    if (pid) {
      refetch();
    }
  }, [pid]);

  const prescription = prescriptionData?.data?.prescription;

  const updatePrescriptionDetailsMutation = useMutation({
    mutationKey: ["update-prescription-details"],
    mutationFn: updatePrescriptionDetails,
    onSuccess: (response) => {
      toast.success(response?.data?.message);
      refetch();
      setIsEditable(false);
    },
  });

  const formik: FormikProps<PrescriptionDetailFormValuesType> =
    useFormik<PrescriptionDetailFormValuesType>({
      validationSchema: PrescriptionDetailSchema,
      initialValues: PrescriptionDetailValues,
      onSubmit: (val: any) => handlePrescriptionUpdate(val),
    });
  const formRef = useRef(formik);

  const handleBillsUpload = (files: any) => {
    setSelectedFiles(files);
  };

  const handleSelectedFile = (file: File) => {
    const fileExists = selectedFiles.some(
      (selectedFile) => selectedFile.name === file.name
    );

    if (fileExists) {
      const updatedFiles = selectedFiles.filter(
        (selectedFile) => selectedFile.name !== file.name
      );
      setSelectedFiles(updatedFiles);
    }
  };

  const handlePrescriptionUpdate = (val: any) => {
    const data: any = {
      bill_number: val?.bill_number,
      total_bill: val?.total_bill,
      deliveryboy_id: val?.deliveryboy_id,
    };

    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    selectedFiles.forEach((file) => {
      formData.append("bills", file);
    });

    // Call the mutation to update the prescription details with the FormData
    updatePrescriptionDetailsMutation.mutate({
      id: prescription?.prescription_id,
      formData,
    });
  };

  const isLoading =
    isLoadingPrescription ||
    updatePrescriptionDetailsMutation.isPending ||
    isFetchingPrescription;

  useEffect(() => {
    if (isLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [isLoading, showLoading, hideLoading]);

  useEffect(() => {
    formRef.current.setFieldValue(
      "bill_number",
      prescription?.Bill?.bill_number
    );
    formRef.current.setFieldValue("total_bill", prescription?.Bill?.total_bill);
    formRef.current.setFieldValue(
      "deliveryboy_id",
      prescription?.deliveryboy_id
    );
  }, [
    prescription?.Bill?.bill_number,
    prescription?.deliveryboy_id,
    prescription?.Bill?.total_bill,
  ]);

  return (
    <>
      {prescription && (
        <div className="flex-1 overflow-y-auto">
          <div className="p-4 flex flex-col gap-8">
            <form onSubmit={formik.handleSubmit}>
              <PrescriptionDetails prescription={prescription} />
              <PatientDetails
                prescription={prescription}
                isEditable={isEditable}
              />
              <div className="grid grid-cols-2 gap-4">
                <BillDetails
                  prescription={prescription}
                  formik={formik}
                  handleBillsUpload={handleBillsUpload}
                  isEditable={isEditable}
                  handleSelectedFile={handleSelectedFile}
                  selectedFile={selectedFiles}
                />
                <DeliveryBoyDetails formik={formik} isEditable={isEditable} />
              </div>
              <div className="flex justify-end gap-6 pt-8">
                {isEditable ? (
                  <div className="flex justify-end gap-6 pt-8">
                    <Button
                      label="Cancel"
                      onClick={() => setIsEditable((prev) => !prev)}
                      type="button"
                      className="text-base font-bold text-success py-4 px-8 border border-gray rounded-[40px] bg-transparent uppercase"
                    />
                    <Button
                      loading={updatePrescriptionDetailsMutation.isPending}
                      disabled={updatePrescriptionDetailsMutation.isPending}
                      label=" Save Details"
                      type="submit"
                      className="text-base font-bold text-white py-4 px-8 rounded-[40px] bg-success uppercase"
                    />
                  </div>
                ) : (
                  <div className="flex justify-end gap-6 pt-8">
                    <Button
                      onClick={() => setIsEditable((prev) => !prev)}
                      label="Edit Details"
                      type="button"
                      className="text-base font-bold text-white py-4 px-8 rounded-[40px] bg-success uppercase"
                    />
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};
