import { FC, useEffect, useState } from "react";
import PlusIcon from "./../../images/plus-icon.svg";

import {
  DeliveryBoyCreateUpdateModal,
  DeliveryBoyList,
} from "../../components/delivery";
import { useDebouncedValue, useDisclosure } from "../../hooks";
import { useQuery } from "@tanstack/react-query";
import { GetDeliveryBoyList } from "../../utils/query";
import { useLoading } from "../../context/LoadingContext"; // Import useLoading
import { Button, Pagination } from "../../components";
import { SearchInput } from "../../components/core";

export const Delivery: FC = () => {
  const [isDeliveryModalOpen, deliveryModalHandler] = useDisclosure(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");

  const [debouncedSearchValue] = useDebouncedValue(search, 200);
  const { showLoading, hideLoading } = useLoading();

  const {
    data: deliveryBoydata,
    isLoading,
    refetch: getDeliveryBoyList,
  } = useQuery({
    queryKey: ["delivery-boy-list"],
    queryFn: () =>
      GetDeliveryBoyList({
        page: currentPage,
        limit: 12,
        search: debouncedSearchValue,
      }),
    enabled: false,
  });

  const totalPages = deliveryBoydata?.data?.totalPages ?? 1;

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  useEffect(() => {
    if (isLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [isLoading, showLoading, hideLoading]);

  useEffect(() => {
    getDeliveryBoyList();
  }, []);

  useEffect(() => {
    getDeliveryBoyList();
  }, [currentPage, getDeliveryBoyList, debouncedSearchValue]);

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="p-4 flex flex-col gap-4 h-full">
        <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4 flex flex-col gap-6 flex-1">
          <div className="flex items-center justify-between gap-4">
            <h3 className="text-dark-black text-base font-semibold">
              Delivery Boys
            </h3>
            <div className="flex gap-6">
              <SearchInput
                value={search}
                onChange={(e) => handleSearch(e.target.value)}
                type="search"
                name="searchPrescription"
                placeholder="Search here..."
                className="border border-gray rounded-lg py-2.5 ps-4 pe-10 min-w-[348px] outline-none placeholder:text-dark-black text-sm font-normal text-primary-black"
              />
              <div className="flex">
                <Button
                  label={
                    <div className="flex items-center gap-2">
                      <span className="w-6 min-w-6 h-6 flex justify-center items-center">
                        <img src={PlusIcon} alt="PlusIcon" />
                      </span>
                      ADD NEW
                    </div>
                  }
                  onClick={deliveryModalHandler.open}
                  type="button"
                  className="flex items-center gap-2 uppercase text-white text-base font-bold bg-success rounded-[20px] py-2 px-4"
                />
              </div>
            </div>
          </div>
          <div className="flex-1">
            <DeliveryBoyList
              isLoading={isLoading}
              deliveryBoydata={deliveryBoydata}
              getDeliveryBoyList={getDeliveryBoyList}
            />
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
          <DeliveryBoyCreateUpdateModal
            title={"Add New"}
            isDeliveryModalOpen={isDeliveryModalOpen}
            deliveryModalHandler={deliveryModalHandler}
            getDeliveryBoyList={getDeliveryBoyList}
          />
        </div>
      </div>
    </div>
  );
};
