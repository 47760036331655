import { Dispatch, FC } from "react";
import { useDisclosure } from "../../../hooks";
import { SortBy } from "../../prescriptions";
import FilterIcon from "./../../../images/Filter.svg";
import searchIcon from "./../../../images/search.svg";
import UndoIcon from "./../../../images/undo-icon.svg";
import { ChipWithSelect } from "../../core/ChipWithSelect";
import { SearchInput } from "../../core";

interface IDoctorHeaderProps {
  doctorData: any;
  search: string;
  selectedStatus: string;
  showStatus: boolean;
  handleSearch: (value: string) => void;
  handleStatus: (value: string) => void;
  handleSortBy: (value: string) => void;
  handleFilter: (value: string) => void;
  setSelectAll: Dispatch<React.SetStateAction<boolean>>;
}

export const DoctorHeader: FC<IDoctorHeaderProps> = ({
  doctorData,
  search,
  selectedStatus,
  showStatus,
  handleSearch,
  handleStatus,
  handleFilter,
  handleSortBy,
  setSelectAll,
}) => {
  const [isFilterOpen, FilterOpenHandler] = useDisclosure(false);

  const statusData = [
    {
      title: "Closed",
      color: "primary-blue",
      status: "closed",
    },
  ];

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-6">
        <h2 className="text-2xl font-semibold text-primary-black">
          {doctorData?.count} Doctors
        </h2>
        {/* <div className="flex items-center gap-4">
          <div className="flex items-center cursor-pointer relative">
            <input
              type="checkbox"
              className="peer h-6 w-6 cursor-pointer transition-all appearance-none rounded-lg border border-gray checked:bg-success checked:border-success"
              id="all-check"
              onClick={() => setSelectAll((prev) => !prev)}
            />
            <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="4"
                viewBox="0 0 15 4"
                fill="none"
              >
                <path
                  d="M2 1.99994H13"
                  stroke="white"
                  stroke-width="2.4"
                  stroke-linecap="round"
                />
              </svg>
            </span>
          </div>
          <label
            htmlFor="all-check"
            className="text-dark-black text-base font-semibold"
          >
            Select All
          </label>
        </div> */}
        {showStatus && (
          <div className="flex gap-2">
            {statusData?.map((status: any, index: number) => (
              <ChipWithSelect
                key={index}
                selected={selectedStatus === status.status}
                color={status?.color}
                title={status?.title}
                onClick={() => handleStatus(status.status)}
              />
            ))}
          </div>
        )}
        {/* <div>
          <button
            type="button"
            className="border border-gray py-2.5 px-4 rounded-[30px] text-sm font-semibold flex items-center gap-4"
          >
            <span className="text-primary-blue ">
              2 selected doctor’s all prescriptions are Closed.
            </span>
            <div className="flex items-center gap-2 text-dark-black">
              <img src={UndoIcon} alt="UndoIcon" />
              <span>Undo</span>
            </div>
          </button>
        </div> */}
      </div>
      <div className="flex items-center gap-6">
        <SearchInput
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
          type="search"
          name="searchPrescription"
          placeholder="Search here..."
          className="border border-gray rounded-lg py-2.5 ps-4 pe-10 min-w-[348px] outline-none placeholder:text-dark-black text-sm font-normal text-primary-black"
        />
        {/* <div className="relative flex">
          <button type="button" onClick={FilterOpenHandler.toggle}>
            <img src={FilterIcon} alt="FilterIcon" />
          </button>
          {isFilterOpen && (
            <SortBy handleSortBy={handleSortBy} handleFilter={handleFilter} />
          )}
        </div> */}
      </div>
    </div>
  );
};
