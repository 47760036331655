import { FormikProps } from "formik";
import { FC, useState } from "react";

interface ISelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  formik: FormikProps<any>;
  options: { value: string | number; label: string }[];
  onChangeValue?: (value: any) => void;
}

export const Select: FC<ISelectProps> = ({
  name,
  label,
  formik,
  options,
  onChangeValue,
  ...props
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const { handleBlur, handleChange, values, errors, touched } = formik;

  return (
    <div className="">
      {label && (
        <label
          htmlFor={name}
          className="text-dark-black text-sm font-normal block my-2 ms-2"
        >
          {label}
          <span className="text-success">*</span>
        </label>
      )}
      <select
        name={name}
        id={name}
        onChange={(e: any) => {
          onChangeValue && onChangeValue(e.target.value);
          handleChange(e);
        }}
        onBlur={() => {
          handleBlur(name);
          setFocus(false);
        }}
        onFocus={() => setFocus(true)}
        value={name ? values[name] : ""}
        {...props}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {name
        ? errors[name] &&
          touched[name] && (
            <span className="text-red">{errors[name] as string}</span>
          )
        : null}
    </div>
  );
};
