import * as Yup from "yup";

export const registrationSchema = Yup.object({
  firstname: Yup.string().required(),
  lastname: Yup.string().required(),
  email: Yup.string().email().required(),
  password: Yup.string().required(),
  is_subscribed: Yup.boolean(),
  date_of_birth: Yup.string(),
  gender: Yup.number(),
});

export const loginSchema = Yup.object({
  email: Yup.string().email().required(),
  password: Yup.string().required(),
});
