import { BrowserPersistence, localStorageKeys } from "../utils";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface CurrentUserType {
  email: string;
  username: string;
}

interface UserState {
  token?: string;
  isSignedIn?: boolean;
  currentUser?: CurrentUserType;
  isAuthorized?: boolean;
}

const storage = new BrowserPersistence();
const token = storage.getItem(localStorageKeys.AUTH_TOKEN);

export const userInitialState: UserState = {
  currentUser: { email: "", username: "" },
  isSignedIn: token ?? false,
  token,
};

const UserSlice = createSlice({
  name: "user",
  initialState: userInitialState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<CurrentUserType>) => {
      return {
        ...state,
        currentUser: action.payload,
      };
    },
    setToken: (state, action: PayloadAction<UserState>) => {
      if (action.payload.token) {
        storage.setItem(
          localStorageKeys.AUTH_TOKEN,
          action.payload.token,
          86400000
        );

        return {
          ...state,
          token: action.payload.token,
          isSignedIn: true,
        };
      }
    },
    clearToken: (state, action) => {
      storage.removeItem(localStorageKeys.AUTH_TOKEN);

      return {
        ...state,
        token: "",
        isSignedIn: false,
        isAuthorized: false,
      };
    },
    setIsAuthorized: (state, action) => {
      return {
        ...state,
        isAuthorized: action.payload,
      };
    },
  },
});

export const { setCurrentUser, setToken, clearToken, setIsAuthorized } =
  UserSlice.actions;
export default UserSlice.reducer;
