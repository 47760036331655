import { FC } from "react";

interface IOrderCountCardProps {
  title: string;
  count: string;
  status: string;
}
export const OrderCountCard: FC<IOrderCountCardProps> = ({
  count,
  title,
  status,
}) => {
  const statusStyles: any = {
    total: {
      bgColor: "bg-unnamed-F7E0FF",
    },
    dispatch: { bgColor: "bg-unnamed-E3F5FF" },
    delivered: { bgColor: "bg-unnamed-DFFFE2" },
    return: { bgColor: "bg-unnamed-f54e8e29" },
  };

  const { bgColor } = statusStyles[status] || {
    bgColor: "bg-gray-100",
  };

  return (
    <div
      className={`${bgColor} p-[30px] rounded-2xl flex items-center justify-between gap-3`}
    >
      <span className="text-primary-black text-xl font-semibold">{title}</span>
      <h2 className="text-4xl text-primary-black font-bold">{count}</h2>
    </div>
  );
};
