import { FC, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { GetAllNotifications } from "../../utils/query";
import { useLoading } from "../../context/LoadingContext";
import moment from "moment";
import { Avatar } from "../../components";

const NotificationItem = ({ item }: any) => {
  return (
    <div className="flex items-center gap-4 py-4 px-4 border-b border-gray hover:bg-gray-lite overflow-x-hidden">
      <div className="w-12 min-w-12 h-12 rounded-full overflow-hidden block">
        {/* <img
          src={CodyFisher}
          alt="CodyFisher"
          className="w-full h-full object-cover"
        /> */}
        <Avatar
          src={item?.User?.profileImage}
          name={`${item?.User?.firstname} ${item?.User?.lastname}`}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="flex items-center flex-1 justify-between gap-4">
        <div className="flex-1">
          <h3 className="text-primary-black text-base font-medium mb-1">
            {item?.User?.firstname} {item?.User?.lastname}
          </h3>
          <p className="text-dark-black text-sm font-normal line-clamp-1">
            {item?.message}
          </p>
        </div>
        {/* <div className="w-full max-w-[300px] min-w-[300px]">
          <button
            type="button"
            className="border border-red text-red rounded-[30px] px-3 py-[3px] text-xs font-semibold whitespace-nowrap"
          >
            Account Delete Request
          </button>
        </div> */}
        <div className="w-full min-w-[300px] max-w-[300px]">
          <p className="text-dark-black text-sm font-normal line-clamp-1 text-end">
            {moment(item?.createdAt).fromNow()}
          </p>
        </div>
      </div>
    </div>
  );
};

export const NotificationPage: FC = () => {
  const { showLoading, hideLoading } = useLoading();

  const {
    data: notifications,
    isLoading: isLoadingNotifications,
    refetch: getAllNotifications,
  } = useQuery({
    queryKey: ["notification"],
    queryFn: () => GetAllNotifications({}),
    enabled: false,
  });

  const isLoading = isLoadingNotifications;

  useEffect(() => {
    if (isLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [isLoading, showLoading, hideLoading]);

  useEffect(() => {
    getAllNotifications();
  }, []);

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="p-4 flex flex-col gap-4">
        <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl py-4">
          <div className="">
            {!isLoadingNotifications &&
              notifications?.data?.notifications?.map(
                (item: any, index: number) => (
                  <NotificationItem key={index} item={item} />
                )
              )}
          </div>
        </div>
      </div>
    </div>
  );
};
