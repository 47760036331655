import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Notification from "./../../images/Notification.svg";
import SearchIcon from "./../../images/search.svg";
import BackIcon from "./../../images/Back-arrow.svg";

export const Header: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Define titles based on route paths, including dynamic detail pages
  const getTitle = () => {
    const { pathname } = location;

    if (pathname === "/") return "Dashboard";
    if (pathname.startsWith("/prescriptions")) return "Prescription";
    if (pathname.startsWith("/doctor")) return "Doctor";
    if (pathname.startsWith("/financial")) return "Financial";
    if (pathname.startsWith("/delivery")) return "Delivery";
    if (pathname.startsWith("/notification")) return "Notification";

    return "Dashboard";
  };

  return (
    <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl py-4 px-6 m-4 mb-0 flex items-center justify-between">
      <div className="flex items-center gap-6">
        <div className="flex items-center gap-2">
          <button
            type="button"
            onClick={() => navigate(-1)} // Navigates back to previous page
            className="w-9 h-9 min-w-9 flex items-center justify-center"
          >
            <img src={BackIcon} alt="Back Arrow" />
          </button>
          <h2 className="text-2xl font-semibold text-primary-black">
            {getTitle()}
          </h2>
        </div>
      </div>
      <div className="flex items-center gap-6">
        <button type="button">
          <img src={SearchIcon} alt="Search" className="w-6 h-6" />
        </button>
        <a href="/notification">
          <img src={Notification} alt="Notification" className="w-6 h-6" />
        </a>
      </div>
    </div>
  );
};
