import { useEffect, useState } from "react";
import { useDebouncedValue } from "./useDebouncedValue";
import { useLoading } from "../context/LoadingContext";
import { useQuery } from "@tanstack/react-query";
import { GetAllDoctor } from "../utils/query";

interface IUseDoctorsProps {
  type?: string;
}

export const useDoctors = ({ type }: IUseDoctorsProps) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [sort, setSort] = useState<string>("");
  const [filter, setFilter] = useState<string>("");
  const [selected, setSelected] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [debouncedSearchValue] = useDebouncedValue(search, 200);
  const { showLoading, hideLoading } = useLoading();

  const {
    data: doctorData,
    isLoading,
    refetch: getAllDoctor,
  } = useQuery({
    queryKey: ["all-doctor"],
    queryFn: () =>
      GetAllDoctor({
        page: currentPage,
        limit: 12,
        search: debouncedSearchValue,
        status: sort,
        filter,
        type,
      }),
    enabled: false,
  });

  const totalPages = doctorData?.data?.totalPages ?? 1;

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleStatus = (value: string) => {
    setStatus(value);
  };

  const handleSortBy = (value: string) => {
    setSort(value);
  };

  const handleFilter = (value: string) => {
    setFilter(value);
  };

  const handleSelected = (event: any) => {
    const productId = parseInt(event.target.value); // Get the product ID from the value attribute
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelected((prev) => [...prev, productId]);
    } else {
      setSelected((prev) => prev.filter((id) => id !== productId));
    }
  };

  useEffect(() => {
    if (isLoading) {
      showLoading(); // Show loader
    } else {
      hideLoading(); // Hide loader
    }
  }, [isLoading, showLoading, hideLoading]);

  useEffect(() => {
    getAllDoctor();
  }, []);

  useEffect(() => {
    getAllDoctor();
  }, [currentPage, getAllDoctor, debouncedSearchValue, filter, sort]);

  useEffect(() => {
    if (selectAll) {
      setSelected(doctorData?.data?.doctor?.map((dr: any) => dr.id));
    } else {
      setSelected([]);
    }
  }, [doctorData?.data?.doctor, selectAll]);
  return {
    doctorData,
    isLoading,
    getAllDoctor,
    selected,
    search,
    status,
    handleSearch,
    handleStatus,
    handleSortBy,
    handleFilter,
    setSelectAll,
    currentPage,
    totalPages,
    handleSelected,
    handlePageChange,
  };
};
