import { useEffect, useMemo, useRef, useState } from "react";
import { Select } from "../../core/Select";
import { useQuery } from "@tanstack/react-query";
import { GetDeliveryBoyList } from "../../../utils/query";

export const DeliveryBoyDetails = ({ formik, isEditable }: any) => {
  const [dBoy, setDBoy] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [deliveryBoyNumber, setDeliveryBoyNumber] = useState<
    string | undefined
  >();

  const [selectedBoy, setSelectedBoy] = useState("");

  const {
    data: deliveryBoydata,
    isLoading,
    refetch: getDeliveryBoyList,
  } = useQuery({
    queryKey: ["delivery-boy-list"],
    queryFn: GetDeliveryBoyList,
    enabled: false,
  });

  useEffect(() => {
    getDeliveryBoyList();
  }, []);

  useEffect(() => {
    if (formik?.values?.deliveryboy_id) {
      const foundBoy = deliveryBoydata?.data?.deliveryBoy?.find(
        (x: any) => x?.d_id === Number(formik?.values?.deliveryboy_id)
      )?.mobile;

      setDeliveryBoyNumber(foundBoy);
    }
  }, [deliveryBoydata?.data?.deliveryBoy, formik?.values?.deliveryboy_id]);

  useEffect(() => {
    if (!isLoading && deliveryBoydata) {
      setDBoy(
        deliveryBoydata?.data?.deliveryBoy?.map((x: any) => {
          return {
            label: x?.name,
            value: x?.d_id?.toString(),
          };
        })
      );
    }
  }, [isLoading, deliveryBoydata]);

  return (
    <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4 flex flex-col gap-6">
      <h2 className="text-2xl text-primary-black font-semibold">
        Choose delivery boy
      </h2>
      <div className="grid grid-cols-4 gap-6">
        <div className="flex flex-col">
          <label className="block text-dark-black text-sm font-normal p-2 pb-0">
            Select{isEditable ? <span className="text-success">*</span> : ""}
          </label>
          {/* <p className="text-base font-semibold text-primary-black p-2">
            Savannah Nguyen
          </p> */}
          <div className="relative bg-down-arrow bg-no-repeat bg-[100%_55%]">
            {dBoy.length && (
              <Select
                formik={formik}
                name="deliveryboy_id"
                className="outline-none appearance-none bg-transparent focus:border-success border-b border-transparent text-primary-black font-semibold text-base w-full p-2 pr-4 cursor-pointer"
                options={dBoy}
                onChangeValue={(val) => {
                  setSelectedBoy(val);
                }}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col">
          <label className="block text-dark-black text-sm font-normal p-2 pb-0">
            Mobile Number
          </label>
          <p className="text-base font-semibold text-primary-black p-2">
            {deliveryBoyNumber ?? ""}
          </p>
        </div>
      </div>
    </div>
  );
};
