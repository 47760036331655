import { FC } from "react";
import { Authorize, DoctorListContainer } from "../../container";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

export const NewDoctor: FC = () => {
  const isAuthorized = useSelector(
    (state: RootState) => state.user.isAuthorized
  );
  return (
    <>
      {isAuthorized && <DoctorListContainer type="new" />}
      <Authorize />
    </>
  );
};
