import { Modal } from "../../../components/Modal";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import SwiperTypes from "swiper";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { useState } from "react";

export const ViewPrescriptionModal = ({
  isPrescriptionModalOpen,
  prescriptionModalHandler,
  data,
}: any) => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperTypes | null>(null);

  return (
    <Modal
      open={isPrescriptionModalOpen}
      onClose={prescriptionModalHandler.close}
      title="Prescription Photos"
      centered
      size="5xl"
    >
      <div className="pt-10 w-full max-w-[408px] mx-auto">
        <div className="">
          <Swiper
            slidesPerView={1}
            pagination={false}
            className="prescription-slider mb-8"
            loop={true}
            spaceBetween={10}
            navigation={false}
            thumbs={{
              swiper:
                thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
            }}
            modules={[FreeMode, Navigation, Thumbs]}
          >
            {data?.map((s: any, index: number) => (
              <SwiperSlide key={index}>
                <div className="">
                  <img
                    src={s}
                    alt="Login-image"
                    className="w-full h-full object-cover rounded-[14px]"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            loop={true}
            spaceBetween={6}
            slidesPerView={"auto"}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="prescription-thumb-slider"
          >
            {data?.map((s: any, index: number) => (
              <SwiperSlide key={index}>
                <div className="">
                  <img
                    src={s}
                    alt="Login-image"
                    className="w-full h-full object-cover rounded-[14px]"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Modal>
  );
};
