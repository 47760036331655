export const LoginValues = {
  username: "",
  password: "",
};

export const DeliveryBoyValues = {
  name: "",
  mobile: "",
};

export const PrescriptionDetailValues = {
  bill_number: "",
  total_bill: 0,
  deliveryboy_id: "",
};

export const CommisionAndDiscountValues = {
  commission: 0,
  discount: 0,
};

export const AuthorizationModalValues = {
  email: "",
};

export const verifyOtpValues = {
  otp: "",
};

export const ChangePasswordValues = {
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
};

export const ResetPasswordValues = {
  username: "",
  currentPassword: "",
  newPassword: "",
  confirmPassword: "",
};
