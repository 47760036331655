import { QueryClient } from "@tanstack/query-core";
import { toast } from "react-toastify";

export const getQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
      },
      mutations: {
        onError: (error: any) => {
          toast.error(error?.response?.data?.message);
        },
      },
    },
  });
