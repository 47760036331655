import React, { FC, useState } from "react";
import OtpInput from "react-otp-input";
import { FormikProps } from "formik";

interface OTPVerificationProps {
  label?: string;
  name: string;
  formik: FormikProps<any>;
}

export const OTPVerification: FC<OTPVerificationProps> = ({
  label,
  name,
  formik,
}) => {
  const { values, errors, touched } = formik;

  const inputStyles = {
    color: "#171B1B",
    width: "36px",
    height: "36px",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "600",
    backgroundColor: "transparent",
    borderBottom: "1px solid #ccc",
    outline: "none",
    margin: "0 8px",
  };

  return (
    <div className="">
      {label && (
        <label className="text-dark-black text-sm font-normal block my-2 ms-2">
          {label}
          <span className="text-success">*</span>
        </label>
      )}
      <OtpInput
        value={values[name] || ""}
        onChange={(val) => formik.setFieldValue(name, val)}
        numInputs={6}
        placeholder="0"
        containerStyle="py-2 flex justify-between"
        inputStyle={inputStyles}
        renderInput={(props) => <input {...props} />}
      />
      {errors[name] && touched[name] && (
        <span className="text-red">{errors[name] as string}</span>
      )}
    </div>
  );
};

export default OTPVerification;
