import { Modal } from "../Modal";
import { FormikProps, useFormik } from "formik";
import { ChangePasswordValuesType } from "../../constants/types";
import { ChangePasswordSchema } from "../../constants/schema";
import { ChangePasswordValues } from "../../constants/data";
import { Input } from "../core";
import { useMutation } from "@tanstack/react-query";
import { adminChangePassword } from "../../utils/mutations";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Button } from "../Button";

export const ResetPasswordModal = ({
  isResertPasswordModalOpen,
  resertPasswordModalHandler,
}: any) => {
  const username = useSelector(
    (state: RootState) => state.user.currentUser?.username
  );

  const formik: FormikProps<ChangePasswordValuesType> =
    useFormik<ChangePasswordValuesType>({
      validationSchema: ChangePasswordSchema,
      initialValues: ChangePasswordValues,
      onSubmit: (val: any) => handleFormSubmit(val),
    });

  const mutation = useMutation({
    mutationKey: ["admin-change-password"],
    mutationFn: adminChangePassword,
    onSuccess: (response) => {
      toast.success(response?.data?.message);
      formik.resetForm();
      resertPasswordModalHandler.close();
    },
  });

  const handleFormSubmit = (val: any) => {
    if (username) {
      const data = {
        username: username,
        oldPassword: val.oldPassword,
        newPassword: val.newPassword,
      };

      mutation.mutate(data);
    }
  };

  return (
    <Modal
      open={isResertPasswordModalOpen}
      onClose={resertPasswordModalHandler.close}
      title="Reset Password"
      centered
      size="5xl"
    >
      <div className="pt-10 w-full max-w-[720px] mx-auto">
        <form onSubmit={formik.handleSubmit} className="flex flex-col gap-6">
          <div>
            <label
              htmlFor="CurrentPassword"
              className="block p-2 pb-0 text-sm font-normal text-dark-black"
            >
              Current Password <span className="text-success">*</span>
            </label>
            <div className="relative">
              <Input
                formik={formik}
                type="password"
                placeholder="Current Password"
                name="oldPassword"
                id="CurrentPassword"
                className="text-primary-black font-semibold text-base placeholder:text-primary-black_05 w-full border-b border-gray focus-within:outline-none p-2 pr-10"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="NewPassword"
              className="block p-2 pb-0 text-sm font-normal text-dark-black"
            >
              New Password <span className="text-success">*</span>
            </label>
            <div className="relative">
              <Input
                formik={formik}
                type="password"
                placeholder="New Password"
                name="newPassword"
                id="NewPassword"
                className="text-primary-black font-semibold text-base placeholder:text-primary-black_05 w-full border-b border-gray focus-within:outline-none p-2 pr-10"
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="ConfirmNewPassword"
              className="block p-2 pb-0 text-sm font-normal text-dark-black"
            >
              Confirm New Password <span className="text-success">*</span>
            </label>
            <div className="relative">
              <Input
                formik={formik}
                type="password"
                placeholder="Confirm New Password"
                name="confirmPassword"
                id="ConfirmNewPassword"
                className="text-primary-black font-semibold text-base placeholder:text-primary-black_05 w-full border-b border-gray focus-within:outline-none p-2 pr-10"
              />
            </div>
          </div>
          <div className="flex justify-end">
            <Button
              label="Reset Password"
              type="submit"
              className="bg-success text-white py-2 px-6 rounded-full hover:bg-opacity-90"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};
