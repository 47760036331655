import moment from "moment";
import { ViewPrescriptionModal } from "../ViewPrescriptionModal";
import { useDisclosure } from "../../../hooks";
import { useNavigate } from "react-router-dom";
import { MainPages } from "../../../Router";
import { Chip } from "../../core";
import { priorityColors } from "../../../utils";
import { Priority } from "../../../constants/types";

export const PrescriptionDetails = ({ prescription }: any) => {
  const [isPrescriptionModalOpen, prescriptionModalHandler] =
    useDisclosure(false);
  const navigate = useNavigate();

  return (
    <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4 flex flex-col gap-6">
      <div className="flex justify-between">
        <h2 className="text-2xl text-primary-black font-semibold capitalize">
          Prescription Details
        </h2>
        <div className="flex items-center gap-2">
          <Chip
            variant="fill"
            title={prescription?.priority ?? "low"}
            color={
              priorityColors[prescription?.priority as Priority] ?? "green"
            }
            className="rounded-md"
          />
          <h2 className="text-xl text-primary-black font-semibold capitalize text-success">
            {prescription?.pr_id}
          </h2>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-6">
        <div className="p-2 flex flex-col items-start gap-2">
          <span className="block text-dark-black text-sm font-normal">
            Status
          </span>
          <div className="capitalize text-xs font-semibold text-yellow border border-yellow py-[3px] px-2 rounded-[30px] inline-block">
            {prescription?.status}
          </div>
        </div>
        <div className="p-2 flex flex-col gap-2">
          <span className="block text-dark-black text-sm font-normal">
            Prescription Photos
          </span>
          <button
            onClick={prescriptionModalHandler.open}
            type="button"
            className="text-success text-start underline text-base font-semibold underline-offset-2 uppercase"
          >
            View
          </button>
          <ViewPrescriptionModal
            data={prescription?.prescriptions}
            isPrescriptionModalOpen={isPrescriptionModalOpen}
            prescriptionModalHandler={prescriptionModalHandler}
          />
        </div>
        <div className="p-2 flex flex-col gap-2">
          <span className="block text-dark-black text-sm font-normal">
            Sent by Doctor
          </span>
          <button
            onClick={() =>
              navigate(`${MainPages.Doctor}/${prescription?.User?.id}`)
            }
            type="button"
            className="text-success text-start underline text-base font-semibold underline-offset-2 uppercase"
          >
            {`${prescription?.User?.firstname} ${prescription?.User?.lastname}`}
          </button>
        </div>
        <div className="p-2 flex flex-col gap-2">
          <span className="block text-dark-black text-sm font-normal">
            Date & Time
          </span>
          <p className="text-base font-semibold text-primary-black">
            {moment(new Date(prescription?.createdAt)).format(
              "DD MMM YYYY hh:mm A"
            )}
          </p>
        </div>
      </div>
    </div>
  );
};
