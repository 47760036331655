import { FormikProps, useFormik } from "formik";
import { AuthorizationModalValuesType } from "../../constants/types";
import { AuthorizationSchema } from "../../constants/schema";
import { AuthorizationModalValues } from "../../constants/data";
import { Input } from "../core";
import { useMutation } from "@tanstack/react-query";
import { sendAdminOtp } from "../../utils/mutations";
import { Button } from "../Button";
import { toast } from "react-toastify";

export const AuthorizationModal = ({
  authorizationModalHandler,
  setIsOtpSend,
  setAdminEmail,
}: any) => {
  const mutation = useMutation({
    mutationKey: ["admin-send-otp"],
    mutationFn: sendAdminOtp,
    onSuccess: (response) => {
      authorizationModalHandler.close();
      setIsOtpSend(true);
      toast.success(response?.data?.message);
    },
  });

  const formik: FormikProps<AuthorizationModalValuesType> =
    useFormik<AuthorizationModalValuesType>({
      validationSchema: AuthorizationSchema,
      initialValues: AuthorizationModalValues,
      onSubmit: (val: any) => handleFormSubmit(val),
    });

  const handleFormSubmit = (val: any) => {
    const data = {
      email: val.email,
    };

    if (val.email) {
      setAdminEmail(val.email);
    }

    mutation.mutate(data);
  };
  return (
    <div className="h-screen fixed p-4 top-0 right-0 z-50 w-full max-w-[calc(100%-256px)] rounded-2xl">
      <div className="h-full backdrop-blur-[60px] rounded-2xl overflow-hidden flex flex-col justify-center items-center">
        <div className="w-full max-w-[648px] mx-auto">
          <div className="flex flex-col gap-12">
            <div className="flex flex-col gap-4">
              <h1 className="text-[40px] font-bold text-primary-black">
                Authorization Require
              </h1>
              <p className="text-base font-normal text-dark-black">
                To access need authorization through register email and otp.
              </p>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-12"
            >
              <div className="flex flex-col">
                <label className="block text-dark-black text-sm font-normal p-2 pb-0">
                  Authorize Email <span className="text-success">*</span>
                </label>
                <Input
                  name="email"
                  formik={formik}
                  type="email"
                  placeholder="Enter Email"
                  className="text-base font-semibold text-primary-black placeholder:text-primary-black outline-none border-b border-gray p-2"
                />
              </div>
              <div className="">
                <Button
                  disabled={mutation.isPending}
                  loading={mutation.isPending}
                  label="Continue"
                  type="submit"
                  className="text-base uppercase font-bold text-white p-3 w-full rounded-3xl bg-success"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
