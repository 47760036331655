import { FormikProps, useFormik } from "formik";
import { AutoComplete, Input, TextArea } from "../../components/core";
import { Button } from "../../components";
import { CreatePrescriptionFormValuesType } from "../../constants/types";
import { CreatePrescriptionSchema } from "../../constants/schema";
import { CreatePrescriptionValues } from "../../constants/data";
import { useMutation, useQuery } from "@tanstack/react-query";
import { GetAllDoctorNames } from "../../utils/query";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useLoading } from "../../context/LoadingContext";
import { createPrescription } from "../../utils/mutations";
import { useNavigate } from "react-router-dom";
import { MainPages } from "../../Router";

import CLoseIcon from "../../images/close-icon.svg";

export const CreatePrescriptionContainer = () => {
  const { showLoading, hideLoading } = useLoading();
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const navigate = useNavigate();

  const { data: doctorsData, isLoading: isLoadingDoctorsQuery } = useQuery({
    queryKey: ["get-all-doctors-names"],
    queryFn: GetAllDoctorNames,
  });

  const mutation = useMutation({
    mutationKey: ["create-prescription"],
    mutationFn: createPrescription,
    onSuccess: (response) => {
      toast.success(response?.data?.message);

      navigate(MainPages.ONBOARD);
    },
  });

  const formik: FormikProps<CreatePrescriptionFormValuesType> =
    useFormik<CreatePrescriptionFormValuesType>({
      validationSchema: CreatePrescriptionSchema,
      initialValues: CreatePrescriptionValues,
      onSubmit: (val: any) => handleSubmit(val),
    });

  const handleSubmit = (val: CreatePrescriptionFormValuesType) => {
    const data: any = {
      user_id: Number(val.user_id),
      patient_name: val.patient_name,
      mobile: val.mobile,
      address: val.address,
      city: val.city,
      near_by: val.near_by,
      prescription_note: val.prescription_note,
      admin_note: val.admin_note,
    };

    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    selectedFiles.forEach((file) => {
      formData.append("images", file);
    });

    mutation.mutate(formData);
  };

  const handlePrescriptionUpload = (files: any) => {
    setSelectedFiles(files);
  };

  const handleSelectedFile = (file: File) => {
    const fileExists = selectedFiles.some(
      (selectedFile) => selectedFile.name === file.name
    );

    if (fileExists) {
      const updatedFiles = selectedFiles.filter(
        (selectedFile) => selectedFile.name !== file.name
      );
      setSelectedFiles(updatedFiles);
    }
  };

  const isLoading = isLoadingDoctorsQuery;

  useEffect(() => {
    if (isLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [isLoading, showLoading, hideLoading]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4 flex flex-col gap-6">
        <h2 className="text-2xl text-primary-black font-semibold capitalize">
          New Prescription
        </h2>
        {doctorsData && (
          <AutoComplete
            formik={formik}
            label="Doctor Name"
            name="user_id"
            className="outline-none appearance-none bg-transparent focus:border-success border-b border-transparent text-primary-black font-semibold text-base w-full p-2 pr-4 cursor-pointer"
            options={doctorsData?.data?.doctors?.map((item: any) => ({
              label: `${item.firstname} ${item.lastname}`,
              value: item.id.toString(),
            }))}
          />
        )}

        <div className="grid grid-cols-1 gap-6">
          <div className="flex flex-col">
            <Input
              formik={formik}
              label="Patient Name"
              name="patient_name"
              placeholder="Patient Name"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-6">
          <div className="flex flex-col">
            <Input
              formik={formik}
              label="Mobile"
              name="mobile"
              placeholder="Mobile"
            />
          </div>

          <div className="flex flex-col">
            <Input
              formik={formik}
              label="Address"
              name="address"
              placeholder="Address"
            />
          </div>
          <div className="flex flex-col">
            <Input
              formik={formik}
              label="City"
              name="city"
              placeholder="City"
            />
          </div>
          <div className="flex flex-col">
            <Input
              formik={formik}
              label="Near By"
              name="near_by"
              placeholder="Near By"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-6">
          <div className="flex flex-col">
            <label className="block text-dark-black text-sm font-normal p-2 pb-2">
              Prescription Notes
            </label>
            <div className="flex flex-col">
              <TextArea
                name="prescription_note"
                placeholder="Prescription Notes"
                formik={formik}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="block text-dark-black text-sm font-normal p-2 pb-2">
              Admin Notes
            </label>
            <div className="flex flex-col">
              <TextArea
                name="admin_note"
                placeholder="Admin Notes"
                formik={formik}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-2">
          <label
            htmlFor="file-upload"
            className="text-success text-start underline text-base font-semibold underline-offset-2 uppercase m-2 cursor-pointer"
          >
            Upload Images
          </label>
          <input
            id="file-upload"
            type="file"
            accept="image/*"
            multiple
            className="hidden"
            onChange={(event) => {
              const files = Array.from(event.target.files as any);
              handlePrescriptionUpload(files);
            }}
          />
          <div className="flex flex-wrap">
            {selectedFiles?.map((file: File, index: number) => {
              const imageUrl = URL.createObjectURL(file); // Create a URL for the image

              return (
                <div
                  key={index}
                  className="relative min-w-[52px] max-w-[52px] h-[52px] rounded overflow-hidden m-2"
                >
                  <img
                    src={imageUrl}
                    alt={file.name}
                    className="w-full h-full object-cover rounded"
                  />
                  <button
                    type="button"
                    className="w-4 min-w-4 h-4 flex justify-center items-center rounded-full bg-white absolute top-0.5 right-0.5"
                    onClick={() => handleSelectedFile(file)}
                  >
                    <img
                      src={CLoseIcon}
                      alt="Close Icon"
                      className="w-2.5 h-2.5 min-w-2.5"
                    />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex justify-end">
          <div className="">
            <Button
              loading={mutation.isPending}
              disabled={mutation.isPending}
              label="Create Prescription"
              type="submit"
              className="text-center bg-success text-white text-base font-bold w-full py-3 px-4 rounded-3xl"
            />
          </div>
        </div>
      </div>
    </form>
  );
};
