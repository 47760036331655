import { Modal as BaseModal, IFrameProps, ModalSize } from "./_modal";

/**
 * Custom modal
 */

interface IModalProps extends IFrameProps {
  title: React.ReactNode;
}

export const Modal: React.FC<IModalProps> = (props) => {
  const {
    open,
    onClose,
    title,
    children,
    size,
    centered = false,
    closeButton = true,
  } = props;

  return (
    <BaseModal.Frame
      open={open}
      onClose={() => onClose()}
      size={size}
      centered={centered}
      closeButton={closeButton}
    >
      {title && <BaseModal.Head>{title}</BaseModal.Head>}
      <BaseModal.Body>{children}</BaseModal.Body>
    </BaseModal.Frame>
  );
};
