import { FC } from "react";
import { DoctorHeader, DoctorsList } from "../../components/doctor";
import { useDoctors } from "../../hooks";

interface IDoctorListContainerProps {
  type?: string;
}

export const DoctorListContainer: FC<IDoctorListContainerProps> = ({
  type,
}) => {
  const {
    doctorData,
    selected,
    search,
    status,
    handleSearch,
    handleStatus,
    handleSortBy,
    handleFilter,
    setSelectAll,
    currentPage,
    totalPages,
    handleSelected,
    handlePageChange,
  } = useDoctors({ type });

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="p-4 flex flex-col gap-4 h-full">
        <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4 flex flex-col flex-1">
          <DoctorHeader
            doctorData={doctorData?.data}
            showStatus={!!selected?.length}
            search={search}
            selectedStatus={status}
            handleSearch={handleSearch}
            handleStatus={handleStatus}
            handleSortBy={handleSortBy}
            handleFilter={handleFilter}
            setSelectAll={setSelectAll}
          />

          <DoctorsList
            doctors={doctorData?.data?.doctor}
            currentPage={currentPage}
            totalPages={totalPages}
            selected={selected}
            handleSelected={handleSelected}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};
