import { FormikProps } from "formik";
import { FC, useState } from "react";

interface ISelectProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  name: string;
  formik: FormikProps<any>;
  options: { value: string | number; label: string }[];
  onChangeValue?: (value: any) => void;
}

export const AutoComplete: FC<ISelectProps> = ({
  name,
  label,
  formik,
  options,
  className,
  onChangeValue,
  ...props
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");

  const { handleBlur, handleChange, values, errors, touched } = formik;

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="relative">
      {label && (
        <label
          htmlFor={name}
          className="text-dark-black text-sm font-normal block my-2 ms-2"
        >
          {label}
          <span className="text-success">*</span>
        </label>
      )}
      <input
        type="text"
        name={name}
        id={name}
        className="text-primary-black font-semibold text-base placeholder:font-normal placeholder:text-primary-black_05 w-full border-b border-gray focus-within:outline-none p-2 pt-0"
        value={searchTerm || values[name] || ""}
        onChange={(e) => {
          setSearchTerm(e.target.value);
          handleChange(e);
          onChangeValue && onChangeValue(e.target.value);
        }}
        onBlur={() => {
          handleBlur(name);
          setFocus(false);
        }}
        onFocus={() => setFocus(true)}
        autoComplete="off"
      />
      {focus && filteredOptions.length > 0 && (
        <ul className="absolute z-10 w-full bg-white border border-gray-300 rounded shadow-md max-h-40 overflow-auto">
          {filteredOptions.map((option) => (
            <li
              key={option.value}
              className="p-2 cursor-pointer hover:bg-gray-200"
              onMouseDown={() => {
                setSearchTerm(option.label);
                formik.setFieldValue(name, option.value);
                onChangeValue && onChangeValue(option.value);
                setFocus(false);
              }}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
      {name
        ? errors[name] &&
          touched[name] && (
            <span className="text-red">{errors[name] as string}</span>
          )
        : null}
    </div>
  );
};
