import { FormikProps } from "formik";
import { FC, useState } from "react";
import EyeIcon from "../../../images/eye-icon.svg";
import EyeSlashIcon from "../../../images/eyeslash-icon.svg";

interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  formik: FormikProps<any>;
}

export const Input: FC<IInputProps> = ({
  name,
  label,
  type = "text",
  formik,
  ...props
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { handleBlur, handleChange, values, errors, touched } = formik;

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="relative">
      {label && (
        <label
          htmlFor={name}
          className="text-dark-black text-sm font-normal block my-2 ms-2"
        >
          {label}
          <span className="text-success">*</span>
        </label>
      )}
      <input
        type={type === "password" && showPassword ? "text" : type}
        name={name}
        id={name}
        className="text-primary-black font-semibold text-base placeholder:font-normal placeholder:text-primary-black_05 w-full border-b border-gray focus-within:outline-none p-2 pt-0"
        onChange={handleChange}
        onBlur={() => {
          handleBlur(name);
          setFocus(false);
        }}
        onFocus={() => setFocus(true)}
        value={name ? values[name] : ""}
        {...props}
      />
      {type === "password" && (
        <span
          onClick={togglePasswordVisibility}
          className="cursor-pointer absolute right-4 top-1/2 -translate-y-1/2 w-5 h-5 flex items-center justify-center"
        >
          {showPassword ? (
            <img src={EyeIcon} alt="Eye-Icon" className="w-full h-full" />
          ) : (
            <img
              src={EyeSlashIcon}
              alt="Eye-Slash-Icon"
              className="w-full h-full"
            />
          )}
        </span>
      )}
      {name
        ? errors[name] &&
          touched[name] && (
            <span className="text-red">{errors[name] as string}</span>
          )
        : null}
    </div>
  );
};
