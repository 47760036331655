import { FC } from "react";
import RightArrow from "./../../../images/Right-arrow.svg";
import CallIcon from "./../../../images/Call-icon.svg";
import TheresaWebb from "./../../../images/TheresaWebb.png";
import mailIcon from "./../../../images/mail.svg";
import { Pagination } from "../../Pagination";
import { useLocation, useNavigate } from "react-router-dom";
import { MainPages } from "../../../Router";
import { Chip } from "../../core";
import { Avatar } from "../../Avatar";

interface DoctorCardProps {
  doctor: any;
  selected: number[];
  handleSelected: (e: any) => void;
}

const DoctorCard: React.FC<DoctorCardProps> = ({
  doctor,
  selected,
  handleSelected,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isFinancial = [
    MainPages.FinancialDoctors,
    MainPages.NewDoctor,
  ].includes(location.pathname as any);

  return (
    <label className="border border-gray hover:border-success checked:border-success rounded-2xl p-4">
      <div className="flex gap-4 justify-between items-start">
        <div className="flex items-center gap-4 flex-1">
          <div className="w-[54px] min-w-[54px] h-[54px] rounded-full overflow-hidden block">
            {/* <img
              src={TheresaWebb}
              alt="TheresaWebb"
              className="w-full h-full object-cover"
            /> */}

            <Avatar
              src={doctor?.profileImage}
              name={`${doctor?.firstname} ${doctor?.lastname}`}
              className="w-full h-full object-cover"
            />
          </div>
          <div>
            <span className="text-dark-black text-sm font-normal block">
              Doctor Name
            </span>
            <h3 className="text-primary-black text-xl font-semibold">
              {doctor?.firstname} {doctor?.lastname}
            </h3>
          </div>
        </div>
        <div className="flex items-center">
          {/* <div className="flex items-center cursor-pointer relative">
            <input
              value={doctor.id}
              checked={selected?.includes(doctor?.id)}
              type="checkbox"
              className="peer h-6 w-6 cursor-pointer transition-all appearance-none rounded-lg border border-gray checked:bg-success checked:border-success"
              id={doctor.id}
              onChange={handleSelected}
            />
            <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none">
              <svg
                width="16"
                height="10"
                viewBox="0 0 16 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 4.07683L5.21251 8.11305C5.42419 8.37902 5.81434 8.41618 6.07244 8.19496L14 1.3999"
                  stroke="white"
                  stroke-width="2.4"
                  stroke-linecap="round"
                />
              </svg>
            </span>
          </div> */}
        </div>
      </div>
      <ul className="flex flex-col gap-6 pt-9">
        <li>
          <span className="text-base font-medium text-primary-black flex items-center gap-2">
            <span className="w-6 h-6 flex items-center justify-center">
              <img src={mailIcon} alt="mailIcon" />
            </span>
            {doctor?.email}
          </span>
        </li>
        <li>
          <span className="text-base font-medium text-primary-black flex items-center gap-2">
            <span className="w-6 h-6 flex items-center justify-center">
              <img src={CallIcon} alt="CallIcon" />
            </span>
            {doctor?.mobile}
          </span>
        </li>
      </ul>
      <div className="flex items-center justify-between pt-10">
        <div className="flex gap-2">
          <Chip color="green" title={`${doctor?.deliveredCount} Delivered`} />
          <Chip color="primary-blue" title={`${doctor?.closedCount} Closed`} />
        </div>
        <button
          type="button"
          className="w-6 h-6 flex items-center justify-center ms-auto"
          onClick={() =>
            navigate(
              `${isFinancial ? MainPages.FinancialDoctors : MainPages.Doctor}/${
                doctor?.id
              }`
            )
          }
        >
          <img src={RightArrow} alt="Right arrow" />
        </button>
      </div>
    </label>
  );
};

interface DoctorListProps {
  doctors: any[];
  currentPage: number;
  totalPages: number;
  selected: number[];
  onPageChange: (page: number) => void;
  handleSelected: (e: any) => void;
}

export const DoctorsList: FC<DoctorListProps> = ({
  currentPage,
  handleSelected,
  onPageChange,
  doctors,
  selected,
  totalPages,
}) => {
  return (
    <div className="flex-1 flex flex-col">
      <div className="flex-1">
        <div className="grid grid-cols-4 gap-4 pt-6">
          {doctors?.map((dr, index) => (
            <DoctorCard
              key={index}
              handleSelected={handleSelected}
              doctor={dr}
              selected={selected}
            />
          ))}
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </div>
  );
};
