import { FC } from "react";
import { Modal } from "../../Modal";
import { useDisclosure } from "../../../hooks";
import Calendar from "../../calendar";

interface ISortByProps {
  excludeStatus?: string[];
  handleSortBy: (value: string) => void;
  handleFilter: (value: string) => void;
}
export const SortBy: FC<ISortByProps> = ({
  excludeStatus = [],
  handleFilter,
  handleSortBy,
}) => {
  const [isCalendarModalOpen, calendarModalHandler] = useDisclosure(false);

  const sortByList = [
    {
      label: "All",
      value: "all",
    },
    {
      label: "Open",
      value: "open",
    },
    {
      label: "Preparing",
      value: "preparing",
    },
    {
      label: "Declined",
      value: "declined",
    },
    {
      label: "Dispatch",
      value: "dispatch",
    },
    {
      label: "Delivered",
      value: "delivered",
    },
    {
      label: "Return",
      value: "return",
    },
    {
      label: "Closed",
      value: "closed",
    },
  ];

  const filterByList = [
    {
      label: "Today",
      value: "today",
    },
    {
      label: "Last 7 Days",
      value: "last_7_days",
    },
    {
      label: "Last 15 Days",
      value: "last_15_days",
    },
    {
      label: "Last Update",
      value: "last_update",
    },
  ];

  return (
    <div className="shadow-[0_0_19px_0_#00000017] flex flex-col gap-6 p-6 rounded-2xl absolute right-0 top-[calc(100%+35px)] z-10 bg-white min-w-[276px]">
      <div className="flex flex-col gap-4">
        <h2 className="text-primary-black text-2xl font-semibold">Sort By</h2>
        <div className="flex flex-col gap-4">
          {sortByList?.map((s, index) => {
            if (!excludeStatus?.includes(s.value)) {
              return (
                <div className="form-group" key={index}>
                  <input
                    type="radio"
                    id={s.value}
                    name="sort"
                    value={s.value}
                    onChange={(e) => handleSortBy(e.target.value)}
                  />
                  <label
                    htmlFor={s.value}
                    className="text-base font-medium text-primary-black"
                  >
                    {s?.label}
                  </label>
                </div>
              );
            }
          })}
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <h2 className="text-primary-black text-2xl font-semibold">Filter</h2>
        <div className="flex flex-col gap-4">
          {filterByList?.map((f, index) => (
            <div className="form-group" key={index}>
              <input
                type="radio"
                id={f.value}
                name="filter"
                value={f.value}
                onChange={(e) => handleFilter(e.target.value)}
              />
              <label
                htmlFor={f.value}
                className="text-base font-medium text-primary-black"
              >
                {f.label}
              </label>
            </div>
          ))}
          <div className="form-group" onClick={calendarModalHandler.open}>
            <input type="radio" id="Custom" name="Filter" />
            <label
              htmlFor="Custom"
              className="text-base font-medium text-primary-black"
            >
              Custom
            </label>
          </div>
        </div>
      </div>
      <Modal
        open={isCalendarModalOpen}
        onClose={calendarModalHandler.close}
        title="Custom Date"
        centered
        size="4xl"
      >
        <div className="pt-10 custom-calendar">
          <Calendar />
        </div>
      </Modal>
    </div>
  );
};
