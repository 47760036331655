import { FC } from "react";

interface IChipProps {
  title: string;
  color?: string;
  variant?: "outline" | "fill";
  className?: string;
}

export const Chip: FC<IChipProps> = ({
  title,
  color = "blue",
  variant = "outline",
  className,
}) => {
  return (
    <span
      className={`capitalize text-xs font-semibold ${
        variant === "fill"
          ? `bg-${color} text-white`
          : `text-${color} border border-${color}`
      } rounded-[30px] py-1 px-2 ${className}`}
    >
      {title}
    </span>
  );
};
