import axios from "axios";
import { BASE_URL, ENDPOINTS } from "./API";
import store from "../redux/store";

export const MainAPI = async (
  method: string,
  point: string,
  data: any,
  form?: any
) => {
  const token = store.getState()?.user?.token;
  return await axios({
    url: `${BASE_URL}${point}`,
    method: method,
    headers: {
      "Content-Type": form ? "multipart/form-data" : "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const loginAdmin = async (data: Object) => {
  return await MainAPI("post", ENDPOINTS.ADMIN_LOGIN, data);
};

export const adminChangePassword = async (data: Object) => {
  return await MainAPI("post", ENDPOINTS.ADMIN_CHANGE_PASSWORD, data);
};

export const adminResetPassword = async (data: Object) => {
  return await MainAPI("post", ENDPOINTS.ADMIN_RESET_PASSWORD, data);
};

export const createDeliveryBoy = async (data: Object) => {
  return await MainAPI("post", ENDPOINTS.DELIVERY_BOY_CREATE, data);
};

export const updateDeliveryBoy = async (data: any) => {
  const { id, ...payload } = data;

  return await MainAPI(
    "patch",
    `${ENDPOINTS.DELIVERY_BOY_UPDATE}${id}`,
    payload
  );
};

export const updatePrescriptionStatus = async (data: any) => {
  return await MainAPI("put", `${ENDPOINTS.UPDATE_PRESCRIPTION_STATUS}`, data);
};

export const updateDiscountCommision = async (data: any) => {
  return await MainAPI("put", `${ENDPOINTS.DISCOUNT_COMMISION_UPDATE}`, data);
};

export const updatePrescriptionDetails = async (data: any) => {
  const { id, formData } = data;
  return await MainAPI(
    "put",
    `${ENDPOINTS.UPDATE_PRESCRIPTION_DETAILS}${id}`,
    formData,
    true
  );
};

export const sendAdminOtp = async (data: Object) => {
  return await MainAPI("post", ENDPOINTS.ADMIN_SEND_OTP, data);
};

export const verifyAdminOtp = async (data: Object) => {
  return await MainAPI("post", ENDPOINTS.ADMIN_VERIFY_OTP, data);
};
