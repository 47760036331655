import RightArrow from "./../../../images/Right-arrow.svg";
import ProfilePlaceholder from "./../../../images/admin-profile.jpg";
import { useQuery } from "@tanstack/react-query";
import { GetRequireDetailsDoctor } from "../../../utils/query";
import { useNavigate } from "react-router-dom";
import { MainPages } from "../../../Router";
import { useLoading } from "../../../context/LoadingContext";
import { useEffect } from "react";
import { Avatar } from "../../Avatar";

const DoctorItem = ({ item }: any) => {
  const navigate = useNavigate();
  return (
    <li className="py-5 first:pt-0 border-b border-gray">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          {item?.firstname && item?.lastname && (
            <Avatar
              src={""}
              name={`${item?.firstname ?? ""} ${item?.lastname}`}
              className="w-full h-full object-cover w-8 h-8"
            />
          )}

          <h3 className="text-primary-black text-base font-normal">
            {`${item?.firstname} ${item?.lastname}`}
          </h3>
        </div>

        <button
          onClick={() => navigate(`/doctor/${item?.id}`)}
          className="w-6 h-6 flex items-center justify-center"
        >
          <img src={RightArrow} alt="RightArrow" />
        </button>
      </div>
    </li>
  );
};

export const RequiredDoctorDetails = () => {
  const { data: doctors, isLoading } = useQuery({
    queryKey: ["require-doctor-details"],
    queryFn: GetRequireDetailsDoctor,
  });

  const { showLoading, hideLoading } = useLoading(); // Access loading context
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      showLoading(); // Show global loading
    } else {
      hideLoading(); // Hide global loading when data is loaded
    }
  }, [isLoading, showLoading, hideLoading]);

  return (
    <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4">
      <div className="flex items-center justify-between pb-4">
        <h2 className="text-primary-black text-2xl font-semibold">
          Require Details
        </h2>
        <button
          onClick={() => navigate(MainPages.Doctor)}
          className="text-dark-black text-sm font-bold underline underline-offset-1"
        >
          View All
        </button>
      </div>
      <ul className="min-h-[340px] max-h-[340px] overflow-y-auto">
        {!isLoading &&
          doctors?.data?.doctor?.map((dr: any, index: number) => (
            <DoctorItem key={index} item={dr} />
          ))}
      </ul>
    </div>
  );
};
