import { ToastContainer } from "react-toastify";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./index.css";
import { Router } from "./Router";
import { GetAdminDetails } from "./utils/query";
import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setCurrentUser } from "./redux/userSlice";
import { useLoading } from "./context/LoadingContext";
import { Loading } from "./components";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const dispatch = useDispatch();
  const { showLoading, hideLoading, isLoading } = useLoading();
  const { data: adminData, isLoading: isLoadingAdmin } = useQuery({
    queryKey: ["admin-details"],
    queryFn: GetAdminDetails,
  });

  useEffect(() => {
    if (isLoadingAdmin) {
      showLoading();
    } else {
      hideLoading();
      if (adminData?.data) {
        dispatch(setCurrentUser(adminData?.data?.admin));
      }
    }
  }, [adminData?.data, dispatch, isLoadingAdmin, showLoading, hideLoading]);

  return (
    <div className="">
      <ToastContainer
        position="top-right"
        newestOnTop={false}
        hideProgressBar={false}
        autoClose={5000}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="flex flex-col h-full w-full">
        <Router />
      </div>
      {isLoading && <Loading />}
    </div>
  );
}

export default App;
