import { useQuery } from "@tanstack/react-query";
import DoctorsBlueIcon from "./../../../images/Doctors-blue.svg";
import PrescriptionsYellowIcon from "./../../../images/Prescriptions-yellow.svg";
import { GetTotalCounts } from "../../../utils/query";
import { useLoading } from "../../../context/LoadingContext";
import { useEffect } from "react";

export const TotalCount = () => {
  const { data: totalCount, isLoading } = useQuery({
    queryKey: ["total-count"],
    queryFn: GetTotalCounts,
  });

  const { showLoading, hideLoading } = useLoading(); // Access loading context

  useEffect(() => {
    if (isLoading) {
      showLoading(); // Show global loading when fetching data
    } else {
      hideLoading(); // Hide global loading when data is loaded
    }
  }, [isLoading, showLoading, hideLoading]);

  return (
    <>
      {!isLoading && totalCount && (
        <div className="flex flex-col gap-4">
          <div className="bg-yellow_04 flex flex-col gap-2.5 flex-1 rounded-2xl p-6 shadow-[0_0_16px_0_#171B1B14]">
            <div className="flex flex-col gap-4">
              <img
                src={PrescriptionsYellowIcon}
                alt="Prescriptions Icon"
                className="w-9 h-9"
              />
              <h3 className="text-xl font-semibold text-yellow">
                Total Prescriptions
              </h3>
            </div>
            <h2 className="text-yellow text-5xl font-bold text-end">
              {totalCount?.data?.totalPrescription.toString().padStart(2, "0")}
            </h2>
          </div>
          <div className="bg-primary-blue_04 flex flex-col gap-2.5 flex-1 rounded-2xl p-6 shadow-[0_0_16px_0_#171B1B14]">
            <div className="flex flex-col gap-4">
              <img
                src={DoctorsBlueIcon}
                alt="Doctors Icon"
                className="w-9 h-9"
              />
              <h3 className="text-xl font-semibold text-primary-blue">
                Total Doctors
              </h3>
            </div>
            <h2 className="text-primary-blue text-5xl font-bold text-end">
              {totalCount?.data?.totalDoctor.toString().padStart(2, "0")}
            </h2>
          </div>
        </div>
      )}
    </>
  );
};
