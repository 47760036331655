import * as Yup from "yup";

export const StringSchema = (val: any) => Yup.string().required(val);

export const LoginSchema = Yup.object().shape({
  username: StringSchema("Username is required"),
  password: Yup.string()
    // .matches(
    //   /^(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.{8,})/,
    //   `Password must contain at least one number,\none uppercase letter, and one special character`
    // )
    .required("Password is a required ")
    .min(6, "Password must be at least 6 characters"),
});

export const CreatePrescriptionSchema = Yup.object().shape({
  user_id: StringSchema("Doctor is required"),
  patient_name: StringSchema("Patient name is required"),
  mobile: StringSchema("Mobile is required"),
  address: StringSchema("Address is required"),
  city: StringSchema("City is required"),
  near_by: StringSchema("Near by is required"),
  prescription_note: Yup.string(),
  admin_note: Yup.string(),
  priority: StringSchema("Priority is required"),
});

export const DeliveryBoySchema = Yup.object().shape({
  name: StringSchema("Name is required"),
  mobile: StringSchema("Mobile is required"),
});

export const PrescriptionDetailSchema = Yup.object().shape({
  bill_number: StringSchema("Bill number is required"),
  total_bill: Yup.number().required("Total boll is required"),
  deliveryboy_id: StringSchema("Delivery boy is require"),
  prescription_note: Yup.string(),
  admin_note: Yup.string(),
  commission_amount: Yup.number(),
});

export const CommisionAndDiscountSchema = Yup.object().shape({
  commission: Yup.number().required("Commission is required"),
  discount: Yup.number().required("Discount is required"),
});

export const AuthorizationSchema = Yup.object().shape({
  email: StringSchema("Email is required"),
});

export const VerifyOtpSchema = Yup.object().shape({
  otp: StringSchema("OTP is required"),
});

export const ChangePasswordSchema = Yup.object().shape({
  oldPassword: StringSchema("Old password is required"),
  newPassword: StringSchema("New password is required"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});

export const ResetPasswordSchema = Yup.object().shape({
  username: StringSchema("Username is required"),
  currentPassword: StringSchema("Old password is required"),
  newPassword: StringSchema("New password is required"),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});
