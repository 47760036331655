import CLoseIcon from "./../../../images/close-icon.svg";
import { Input } from "../../core";
import { useEffect, useState } from "react";
import { useDisclosure } from "../../../hooks";
import { ViewPrescriptionModal } from "../ViewPrescriptionModal";

export const BillDetails = ({
  prescription,
  handleBillsUpload,
  formik,
  isEditable,
  handleSelectedFile,
  selectedFile,
}: any) => {
  const [discount, setDiscount] = useState<number>();
  const [commission, setCommission] = useState<number>();
  const [isPrescriptionModalOpen, prescriptionModalHandler] =
    useDisclosure(false);

  useEffect(() => {
    if (prescription?.User?.discount) {
      setDiscount(
        (prescription?.Bill?.total_bill / 100) * prescription?.User?.discount
      );
    }
  }, [prescription?.Bill?.total_bill, prescription?.User?.discount]);

  useEffect(() => {
    if (prescription?.User?.commission) {
      setCommission(
        (prescription?.Bill?.total_bill / 100) * prescription?.User?.commission
      );
    }
  }, [prescription?.Bill?.total_bill, prescription?.User?.commission]);

  return (
    <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4 flex flex-col gap-6">
      <h2 className="text-2xl text-primary-black font-semibold capitalize">
        Bill Details
      </h2>
      <div className="grid grid-cols-4 gap-6">
        <div className="flex flex-col">
          <label className="block text-dark-black text-sm font-normal p-2 pb-0">
            Bill Number
            {isEditable ? <span className="text-success">*</span> : ""}
          </label>
          <Input
            formik={formik}
            name="bill_number"
            type="text"
            placeholder="Bill Number"
            className={`text-base font-semibold text-primary-black w-full placeholder:text-primary-black_05 outline-none border-b border-transparent p-2 ${
              isEditable ? "focus:border-success" : ""
            }`}
            readOnly={!isEditable}
          />
        </div>
        <div className="flex flex-col">
          <label className="block text-dark-black text-sm font-normal p-2 pb-0">
            Total Bill
            {isEditable ? <span className="text-success">*</span> : ""}
          </label>
          <Input
            formik={formik}
            name="total_bill"
            type="number"
            placeholder="Total Bill"
            className={`text-base font-semibold text-primary-black w-full placeholder:text-primary-black_05 outline-none border-b border-transparent p-2 ${
              isEditable ? "focus:border-success" : ""
            }`}
            readOnly={!isEditable}
          />
        </div>
        {/* <div className="flex flex-col">
          <label className="block text-dark-black text-sm font-normal p-2 pb-0">
            Doctor Commission
          </label>
          <p className="text-base font-semibold text-primary-black p-2">
            {commission ?? 0}{" "}
            {isEditable ? (
            <span className="text-dark-black font-normal">{`(${
              prescription?.User?.commission ?? 0
            }%)`}</span>
          ):("")}
          </p>
        </div>
        <div className="flex flex-col">
          <label className="block text-dark-black text-sm font-normal p-2 pb-0">
            Patient Discount
          </label>
          <p className="text-base font-semibold text-primary-black p-2">
            {discount ?? 0}{" "}
            {isEditable ? (
            <span className="text-dark-black font-normal">{`(${
              prescription?.User?.discount ?? 0
            }%)`}</span>
          ):("")}
          </p>
        </div> */}
        <div className="flex justify-between">
          <div className="flex flex-col items-start">
            <span className="block text-dark-black text-sm font-normal p-2 pb-0">
              Bill Photo/Pdf
            </span>
            {isEditable ? (
              <div className="flex gap-2">
                <label
                  htmlFor="file-upload"
                  className="text-success text-start underline text-base font-semibold underline-offset-2 uppercase m-2 cursor-pointer"
                >
                  Upload Images
                </label>
                <input
                  id="file-upload"
                  type="file"
                  accept="image/*"
                  multiple
                  className="hidden"
                  onChange={(event) => {
                    const files = Array.from(event.target.files as any);
                    handleBillsUpload(files);
                  }}
                />
                <div className="flex flex-wrap">
                  {selectedFile?.map((file: File, index: number) => {
                    const imageUrl = URL.createObjectURL(file); // Create a URL for the image

                    return (
                      <div
                        key={index}
                        className="relative min-w-[52px] max-w-[52px] h-[52px] rounded overflow-hidden m-2"
                      >
                        <img
                          src={imageUrl}
                          alt={file.name}
                          className="w-full h-full object-cover rounded"
                        />
                        <button
                          type="button"
                          className="w-4 min-w-4 h-4 flex justify-center items-center rounded-full bg-white absolute top-0.5 right-0.5"
                          onClick={() => handleSelectedFile(file)}
                        >
                          <img
                            src={CLoseIcon}
                            alt="Close Icon"
                            className="w-2.5 h-2.5 min-w-2.5"
                          />
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <>
                <button
                  onClick={prescriptionModalHandler.open}
                  type="button"
                  className="text-success text-start underline text-base font-semibold underline-offset-2 uppercase m-2"
                >
                  View Bill
                </button>
                <ViewPrescriptionModal
                  data={prescription?.Bill?.bills}
                  isPrescriptionModalOpen={isPrescriptionModalOpen}
                  prescriptionModalHandler={prescriptionModalHandler}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
