interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  totalPages,
  onPageChange,
}) => {
  const leftArrowDisabled = currentPage === 1;
  const rightArrowDisabled = currentPage === totalPages;

  return (
    <div className="flex items-center justify-end gap-2 pt-2">
      <div className="border border-gray rounded-xl py-2 px-4 flex items-center gap-1">
        <button
          type="button"
          className="w-3 h-3 flex items-center justify-center"
          disabled={currentPage === 1}
          onClick={() => onPageChange(currentPage - 1)}
        >
          <svg
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.59941 11.7771C6.75442 11.7019 7.21058 11.2235 7.27701 11.0686C7.34344 10.9159 7.34344 10.6972 7.27701 10.5445C7.23715 10.4489 6.87842 10.0707 5.07593 8.21384L2.92357 5.99933L5.08479 3.77115C7.48073 1.30602 7.33015 1.47917 7.33015 1.16932C7.32794 0.941494 7.27037 0.83897 6.98028 0.540512C6.67913 0.230663 6.57063 0.16687 6.33369 0.16687C6.21633 0.16687 6.15211 0.18054 6.06797 0.221549C5.97939 0.264837 5.4568 0.791126 3.34209 2.97146C1.19194 5.18825 0.720285 5.68493 0.695927 5.76011C0.647211 5.90592 0.658283 6.15198 0.715856 6.28412C0.757929 6.37981 1.20302 6.84686 3.35095 9.05682C6.22076 12.0095 6.02811 11.8318 6.33369 11.8318C6.45105 11.8318 6.51527 11.8181 6.59941 11.7771Z"
              fill={leftArrowDisabled ? "#E0E3E3" : "#FE615A"}
            />
          </svg>
        </button>
        <div className="text-sm font-normal text-success w-10 min-w-10 text-center">
          {currentPage} |
        </div>
        <button
          type="button"
          className="w-3 h-3 flex items-center justify-center"
          disabled={currentPage === totalPages}
          onClick={() => onPageChange(currentPage + 1)}
        >
          <svg
            viewBox="0 0 8 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1.39863 11.7771C1.24363 11.7019 0.787468 11.2235 0.721038 11.0686C0.654607 10.9159 0.654607 10.6972 0.721038 10.5445C0.760896 10.4489 1.11962 10.0707 2.92211 8.21384L5.07447 5.99933L2.91326 3.77115C0.517316 1.30602 0.667893 1.47917 0.667893 1.16932C0.670107 0.941494 0.727681 0.83897 1.01776 0.540512C1.31892 0.230663 1.42742 0.16687 1.66436 0.16687C1.78172 0.16687 1.84593 0.18054 1.93008 0.221549C2.01865 0.264837 2.54124 0.791126 4.65596 2.97146C6.8061 5.18825 7.27776 5.68493 7.30212 5.76011C7.35084 5.90592 7.33976 6.15198 7.28219 6.28412C7.24012 6.37981 6.79503 6.84686 4.6471 9.05682C1.77729 12.0095 1.96994 11.8318 1.66436 11.8318C1.54699 11.8318 1.48278 11.8181 1.39863 11.7771Z"
              fill={rightArrowDisabled ? "#E0E3E3" : "#FE615A"}
            />
          </svg>
        </button>
      </div>
      <p className="text-dark-black text-sm font-normal">
        of {totalPages} pages
      </p>
    </div>
  );
};
