import { FC } from "react";
import MediVahanLogo from "./../../images/medivahan-logo.svg";
import DrImage from "./../../images/Dr-img.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Button } from "../../components";
import { useNavigate } from "react-router-dom";
import { FormikProps, useFormik } from "formik";
import { ResetPasswordValuesType } from "../../constants/types";
import { ResetPasswordSchema } from "../../constants/schema";
import { ResetPasswordValues } from "../../constants/data";
import { Input } from "../../components/core";
import { adminResetPassword } from "../../utils/mutations";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { AuthPages } from "../../Router";

export const ResetPassword: FC = () => {
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationKey: ["admin-reset-password"],
    mutationFn: adminResetPassword,
    onSuccess: (response) => {
      toast.success(response?.data?.message);
      formik.resetForm();
      navigate(AuthPages.LOGIN);
    },
  });

  const formik: FormikProps<ResetPasswordValuesType> =
    useFormik<ResetPasswordValuesType>({
      validationSchema: ResetPasswordSchema,
      initialValues: ResetPasswordValues,
      onSubmit: (val: any) => handleFormSubmit(val),
    });

  const handleFormSubmit = (val: any) => {
    const data = {
      username: val.username,
      currentPassword: val.currentPassword,
      newPassword: val.newPassword,
    };

    mutation.mutate(data);
  };
  return (
    <div className="p-12 h-screen">
      <div className="grid grid-cols-2 gap-12 items-center h-full">
        <div className="px-[175px] pt-[40px] h-full bg-gradient-bg bg-no-repeat bg-cover">
          <div className="text-center">
            <img src={MediVahanLogo} alt="MediVahan-logo" className="mx-auto" />
          </div>
          <div className="pt-[116px]">
            <Swiper
              modules={[Pagination]}
              spaceBetween={20}
              slidesPerView={1}
              pagination={true}
              className="reset-password-slider"
            >
              <SwiperSlide>
                <div className="">
                  <div className="">
                    <img src={DrImage} alt="Dr-image" className="mx-auto" />
                  </div>
                  <div className="pt-12 flex flex-col gap-4 text-center">
                    <h2 className="text-[40px] font-bold text-primary-black">
                      Track your records
                    </h2>
                    <p className="text-sm text-dark-black font-normal">
                      Track your records Lorem ipsum dolor sit amet consectetur.
                      Magna tellus cras et sed mauri adipiscing convallis est
                      ullamcorper
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="">
                  <div className="">
                    <img src={DrImage} alt="Login-image" className="mx-auto" />
                  </div>
                  <div className="pt-12 flex flex-col gap-4 text-center">
                    <h2 className="text-[40px] font-bold text-primary-black">
                      Welcome to MediVahan
                    </h2>
                    <p className="text-sm text-dark-black font-normal">
                      Let us take full ownership and deliver our very best to
                      our patients.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="">
                  <div className="">
                    <img src={DrImage} alt="Login-image" className="mx-auto" />
                  </div>
                  <div className="pt-12 flex flex-col gap-4 text-center">
                    <h2 className="text-[40px] font-bold text-primary-black">
                      Welcome to MediVahan
                    </h2>
                    <p className="text-sm text-dark-black font-normal">
                      Let us take full ownership and deliver our very best to
                      our patients.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="px-[120px]">
          <div className="flex flex-col gap-4">
            <h1 className="text-primary-black text-[40px] font-bold">
              Reset Password
            </h1>
          </div>
          <div className="pt-12">
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-12"
            >
              <div className="flex flex-col gap-6">
                <div className="">
                  <label
                    htmlFor="username"
                    className="text-dark-black text-sm font-normal block my-2 ms-2"
                  >
                    User Name<span className="text-success">*</span>
                  </label>
                  <Input
                    formik={formik}
                    type="text"
                    placeholder="Enter Username"
                    name="username"
                    id="username"
                    className="text-primary-black font-semibold text-base placeholder:text-primary-black_05 w-full border-b border-gray focus-within:outline-none p-2 pt-0 pr-10"
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="currentPassword"
                    className="text-dark-black text-sm font-normal block my-2 ms-2"
                  >
                    Current Password<span className="text-success">*</span>
                  </label>
                  <Input
                    formik={formik}
                    type="password"
                    placeholder="Enter Current Password"
                    name="currentPassword"
                    id="currentPassword"
                    className="text-primary-black font-semibold text-base placeholder:text-primary-black_05 w-full border-b border-gray focus-within:outline-none p-2 pt-0 pr-10"
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="newPassword"
                    className="text-dark-black text-sm font-normal block my-2 ms-2"
                  >
                    New Password<span className="text-success">*</span>
                  </label>
                  <Input
                    formik={formik}
                    type="password"
                    placeholder="Enter New Password"
                    name="newPassword"
                    id="newPassword"
                    className="text-primary-black font-semibold text-base placeholder:text-primary-black_05 w-full border-b border-gray focus-within:outline-none p-2 pt-0 pr-10"
                  />
                </div>
                <div className="">
                  <label
                    htmlFor="confirmPassword"
                    className="text-dark-black text-sm font-normal block my-2 ms-2"
                  >
                    Confirm Password<span className="text-success">*</span>
                  </label>
                  <Input
                    formik={formik}
                    type="password"
                    placeholder="Enter Confirm Password"
                    name="confirmPassword"
                    id="confirmPassword"
                    className="text-primary-black font-semibold text-base placeholder:text-primary-black_05 w-full border-b border-gray focus-within:outline-none p-2 pt-0 pr-10"
                  />
                </div>
              </div>
              <div className="">
                <Button
                  loading={mutation.isPending}
                  disabled={mutation.isPending}
                  label="SAVE"
                  type="submit"
                  className="uppercase text-center bg-success text-white text-base font-bold w-full py-3 px-4 rounded-3xl"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
