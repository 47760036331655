export const PatientDetails = ({ prescription,isEditable }: any) => {
  return (
    <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4 flex flex-col gap-6">
      <h2 className="text-2xl text-primary-black font-semibold capitalize">
        Patient Details
      </h2>
      <div className="grid grid-cols-4 gap-6">
        <div className="flex flex-col">
          <label className="block text-dark-black text-sm font-normal p-2 pb-0">
            Name{isEditable ? (<span className="text-success">*</span>):("")}
          </label>
          <input
            value={prescription?.patient_name}
            type="text"
            placeholder="Name"
            className={`text-base font-semibold text-primary-black placeholder:text-primary-black_05 outline-none border-b border-transparent p-2 ${isEditable ? "focus:border-success":""}`}
            readOnly={!isEditable}
          />
        </div>
        <div className="flex flex-col">
          <label className="block text-dark-black text-sm font-normal p-2 pb-0">
            Mobile Number{isEditable ? (<span className="text-success">*</span>):("")}
          </label>
          <input
            value={prescription?.mobile}
            type="tel"
            placeholder="Mobile Number"
            className={`text-base font-semibold text-primary-black placeholder:text-primary-black_05 outline-none border-b border-transparent p-2 ${isEditable ? "focus:border-success":""}`}
            readOnly={!isEditable}
          />
        </div>
        <div className="flex flex-col">
          <label className="block text-dark-black text-sm font-normal p-2 pb-0">
            Email ID{isEditable ? (<span className="text-success">*</span>):("")}
          </label>
          <input
            required={false}
            value={prescription?.email}
            type="email"
            placeholder="Email ID"
            className={`text-base font-semibold text-primary-black placeholder:text-primary-black_05 outline-none border-b border-transparent p-2 ${isEditable ? "focus:border-success":""}`}
            readOnly={!isEditable}
          />
        </div>
        <div className="flex flex-col">
          <label className="block text-dark-black text-sm font-normal p-2 pb-0">
            Address{isEditable ? (<span className="text-success">*</span>):("")}
          </label>
          <input
            value={prescription?.address}
            type="text"
            placeholder="Address"
            className={`text-base font-semibold text-primary-black placeholder:text-primary-black_05 outline-none border-b border-transparent p-2 ${isEditable ? "focus:border-success":""}`}
            readOnly={!isEditable}
          />
        </div>
      </div>
    </div>
  );
};
