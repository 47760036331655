import { useNavigate } from "react-router-dom";
import { MainPages } from "../../../Router";
import { useLoading } from "../../../context/LoadingContext";
import { useQuery } from "@tanstack/react-query";
import { GetAllNotifications } from "../../../utils/query";
import { useEffect } from "react";
import moment from "moment";
import { Avatar } from "../../Avatar";

const LatestNotificationsItem = ({ notification }: any) => {
  return (
    <li className="py-5 first:pt-2 border-b border-gray">
      <div className="flex items-center gap-2  overflow-x-hidden">
        <div className="w-12 min-w-12 h-12 rounded-full overflow-hidden block">
          {/* <img
            src={CodyFisher}
            alt="CodyFisher"
            className="w-full h-full object-cover"
          /> */}
          <Avatar
            src={notification?.User?.profileImage}
            name={`${notification?.User?.firstname} ${notification?.User?.lastname}`}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex items-center flex-1 justify-between gap-7">
          <div className="">
            <h3 className="text-primary-black text-base font-medium mb-1">
              {notification?.User?.firstname} {notification?.User?.lastname}
            </h3>
            <p className="text-dark-black text-sm font-normal line-clamp-1">
              {notification?.message}
            </p>
          </div>
          <div className="">
            <p className="text-dark-black text-sm font-normal line-clamp-1 text-end mt-1">
              {moment(notification?.created_at).fromNow()}
            </p>
          </div>
        </div>
      </div>
    </li>
  );
};

export const LatestNotifications = () => {
  const navigate = useNavigate();

  const { showLoading, hideLoading } = useLoading();

  const {
    data: notifications,
    isLoading: isLoadingNotifications,
    refetch: getAllNotifications,
  } = useQuery({
    queryKey: ["notification"],
    queryFn: () =>
      GetAllNotifications({
        limit: 5,
      }),
    enabled: false,
  });

  const isLoading = isLoadingNotifications;

  useEffect(() => {
    if (isLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [isLoading, showLoading, hideLoading]);

  useEffect(() => {
    getAllNotifications();
  }, []);

  return (
    <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4">
      <div className="flex items-center justify-between pb-4">
        <h2 className="text-primary-black text-2xl font-semibold">
          Latest Notifications
        </h2>
        <button
          className="text-dark-black text-sm font-bold underline underline-offset-1"
          onClick={() => navigate(MainPages.Notification)}
        >
          View All
        </button>
      </div>
      <ul className="min-h-[340px] max-h-[340px] overflow-y-auto">
        {notifications?.data?.notifications?.map(
          (notification: any, index: number) => (
            <LatestNotificationsItem key={index} notification={notification} />
          )
        )}
      </ul>
    </div>
  );
};
