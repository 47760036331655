import { FC } from "react";
import MediVahanLogo from "./../../images/medivahan-logo.svg";
import LoginImage from "./../../images/login-img.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { Input } from "../../components/core";
import { FormikProps, useFormik } from "formik";
import { LoginFormValuesType, LoginSchema, LoginValues } from "../../constants";
import { useMutation } from "@tanstack/react-query";
import { loginAdmin } from "../../utils/mutations";
import { useDispatch } from "react-redux";
import { setToken } from "../../redux/userSlice";
import { useNavigate } from "react-router-dom";
import { AuthPages, MainPages } from "../../Router";
import { Button } from "../../components";
import { toast } from "react-toastify";

export const Login: FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationKey: ["admin-login"],
    mutationFn: loginAdmin,
    onSuccess: (response) => {
      toast.success(response?.data?.message);
      dispatch(setToken({ token: response?.data?.accessToken }));
      navigate(MainPages.ONBOARD);
    },
  });

  const formik: FormikProps<LoginFormValuesType> =
    useFormik<LoginFormValuesType>({
      validationSchema: LoginSchema,
      initialValues: LoginValues,
      onSubmit: (val: any) => handleLogin(val),
    });

  const handleLogin = (val: any) => {
    const data = {
      username: val.username,
      password: val.password?.trim(),
    };

    mutation.mutate(data);
  };

  return (
    <div className="p-12 h-screen">
      <div className="grid grid-cols-2 gap-12 items-center h-full">
        <div className="px-[175px] pt-[40px] h-full bg-gradient-bg bg-no-repeat bg-cover">
          <div className="text-center">
            <img src={MediVahanLogo} alt="MediVahan-logo" className="mx-auto" />
          </div>
          <div className="pt-[116px]">
            <Swiper
              modules={[Pagination]}
              spaceBetween={20}
              slidesPerView={1}
              pagination={true}
              className="login-slider"
            >
              <SwiperSlide>
                <div className="">
                  <div className="">
                    <img
                      src={LoginImage}
                      alt="Login-image"
                      className="mx-auto"
                    />
                  </div>
                  <div className="pt-12 flex flex-col gap-4 text-center">
                    <h2 className="text-[40px] font-bold text-primary-black">
                      Welcome to MediVahan
                    </h2>
                    <p className="text-sm text-dark-black font-normal">
                      Let us take full ownership and deliver our very best to
                      our patients.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="">
                  <div className="">
                    <img
                      src={LoginImage}
                      alt="Login-image"
                      className="mx-auto"
                    />
                  </div>
                  <div className="pt-12 flex flex-col gap-4 text-center">
                    <h2 className="text-[40px] font-bold text-primary-black">
                      Welcome to MediVahan
                    </h2>
                    <p className="text-sm text-dark-black font-normal">
                      Let us take full ownership and deliver our very best to
                      our patients.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="">
                  <div className="">
                    <img
                      src={LoginImage}
                      alt="Login-image"
                      className="mx-auto"
                    />
                  </div>
                  <div className="pt-12 flex flex-col gap-4 text-center">
                    <h2 className="text-[40px] font-bold text-primary-black">
                      Welcome to MediVahan
                    </h2>
                    <p className="text-sm text-dark-black font-normal">
                      Let us take full ownership and deliver our very best to
                      our patients.
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
        <div className="px-[120px]">
          <div className="flex flex-col gap-4">
            <h1 className="text-primary-black text-[40px] font-bold">Login</h1>
            <p className="text-dark-black text-sm font-normal">
              Login using your ID and Password
            </p>
          </div>
          <div className="pt-12">
            <form
              className="flex flex-col gap-12"
              onSubmit={formik.handleSubmit}
            >
              <div className="flex flex-col gap-6">
                <Input
                  name="username"
                  label="Admin ID"
                  type="text"
                  placeholder="John Wick"
                  formik={formik}
                />
                <Input
                  name="password"
                  label="Password"
                  type="password"
                  placeholder="******"
                  formik={formik}
                />

                <div className="text-end">
                  <button
                    onClick={() => navigate(AuthPages.RESETPASSWORD)}
                    type="button"
                    className="uppercase text-success text-sm font-normal underline underline-offset-2"
                  >
                    Reset Password?
                  </button>
                </div>
              </div>
              <div className="">
                <Button
                  loading={mutation.isPending}
                  disabled={mutation.isPending}
                  label="LOGIN"
                  type="submit"
                  className="uppercase text-center bg-success text-white text-base font-bold w-full py-3 px-4 rounded-3xl"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
