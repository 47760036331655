import axios from "axios";
import { BASE_URL, ENDPOINTS } from "./API";
import store from "../redux/store";
import { objectToQueryString } from "./func";

export const axiosCall = axios.create({
  baseURL: BASE_URL,
});

export const mainCall = async (url: any) => {
  const token = store.getState()?.user?.token;
  return axios({
    method: "GET",
    url: `${BASE_URL}${url}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const GetCalls = async (point: string) => {
  return axios({
    method: "GET",
    url: `${BASE_URL}${point}`,
    headers: {},
  });
};

export const GetAdminDetails = () => {
  return mainCall(`${ENDPOINTS.ADMIN_DETAILS}`);
};

export const GetRecentPrescription = () => {
  return mainCall(`${ENDPOINTS.RECENT_PRESCRIPTION}/`);
};

export const GetRequireDetailsDoctor = () => {
  return mainCall(`${ENDPOINTS.REQUIRE_DETAILS_DOCTORS}/`);
};

export const GetTotalCounts = () => {
  return mainCall(`${ENDPOINTS.GET_COUNTS}/`);
};

export const GetPrescriptionDetails = (id: number) => {
  return mainCall(`${ENDPOINTS.PRESCRIPTION_DETAILS}${id}`);
};

export const GetDeliveryBoyDetails = (id: number) => {
  return mainCall(`${ENDPOINTS.DELIVERY_BOY_DETAILS}${id}`);
};

export const GetDeliveryBoyList = (params: Record<string, any>) => {
  const queryString = objectToQueryString(params);
  const url = `${ENDPOINTS.DELIVERY_BOY_LIST}?${queryString}`;

  return mainCall(url);
};

export const GetPrescriptionStatusCount = () => {
  return mainCall(`${ENDPOINTS.GET_PRESCRIPTION_STATUS_COUNT}`);
};

export const GetPrescriptionStatusCountByDoctore = (id: number) => {
  return mainCall(`${ENDPOINTS.GET_PRESCRIPTION_STATUS_COUNT_BY_DOCTOR}${id}`);
};

export const GetPrescriptionStatusCountByDeliveryBoy = (id: number) => {
  return mainCall(
    `${ENDPOINTS.GET_PRESCRIPTION_STATUS_COUNT_BY_DELIVERY_BOY}${id}`
  );
};

export const GetDoctorDetails = (id: number) => {
  return mainCall(`${ENDPOINTS.DOCTOR_DETAILS}${id}`);
};

export const GetTotalPaidAndTotalDue = (id: number) => {
  return mainCall(`${ENDPOINTS.TOTAL_PAID_AND_TOTAL_DUE}${id}`);
};

export const GetFinanceGraphData = () => {
  return mainCall(`${ENDPOINTS.GET_FINANCE_GRAPH_DATA}`);
};

export const GetAllPrescriptions = (params: Record<string, any>) => {
  const queryString = objectToQueryString(params);
  const url = `${ENDPOINTS.GET_ALL_PRESCRIPTION}?${queryString}`;

  return mainCall(url);
};

export const GetPrescriptionsByDoctor = (params: Record<string, any>) => {
  const queryString = objectToQueryString(params);
  const url = `${ENDPOINTS.GET_PRESCRIPTION_BY_DOCTOR}?${queryString}`;

  return mainCall(url);
};

export const GetPrescriptionsByDeliveryBoy = (params: Record<string, any>) => {
  const queryString = objectToQueryString(params);
  const url = `${ENDPOINTS.GET_PRESCRIPTION_BY_DELIVERY_BOY}?${queryString}`;

  return mainCall(url);
};

export const GetPrescriptionForFinance = (params: Record<string, any>) => {
  const queryString = objectToQueryString(params);
  const url = `${ENDPOINTS.GET_ALL_PRESCRIPTION_FOR_FINANCE}?${queryString}`;

  return mainCall(url);
};

export const GetAllDoctor = (params: Record<string, any>) => {
  const queryString = objectToQueryString(params);
  const url = `${ENDPOINTS.GET_ALL_DOCTOR}?${queryString}`;

  return mainCall(url);
};

export const GetAllNotifications = (params: Record<string, any>) => {
  const queryString = objectToQueryString(params);
  const url = `${ENDPOINTS.GET_ALL_NOTIFICATION}?${queryString}`;

  return mainCall(url);
};
