"use client";
import clsx from "clsx";
import React, { useEffect, useRef } from "react";
import CLoseIcon from "./../../images/Modal-close.svg";

export type ModalSize =
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
  | "4xl"
  | "5xl"
  | "6xl"
  | "7xl"
  | "8xl"
  | "full";

export interface IFrameProps {
  closeOnClickOutside?: boolean;
  closeOnEsc?: boolean;
  onClose: () => void;
  open?: boolean;
  size?: ModalSize;
  centered?: boolean;
  closeButton?: boolean;
  children: React.ReactNode;
}
interface IHeadProps {
  children: React.ReactNode;
}
interface IBodyProps {
  children: React.ReactNode;
}

const Frame: React.FC<IFrameProps> = ({
  children,
  closeOnClickOutside = true,
  closeOnEsc = true,
  onClose,
  open = true,
  size = "md",
  centered = false,
  closeButton = false,
}) => {
  useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      if (closeOnEsc && open && e.key === "Escape") onClose();
    };

    window.addEventListener("keydown", onKeyPress);
    return () => window.removeEventListener("keydown", onKeyPress);
  }, [closeOnEsc, onClose, open]);

  const container = useRef<HTMLDivElement>(null);
  const onOverlayClick = (e: React.MouseEvent) => {
    if (!container.current?.contains(e.target as Node)) onClose();
  };

  return (
    <div
      className={clsx(
        "fixed inset-0 z-50 bg-black_04",
        `${open ? "visible" : "invisible"}`,
        [
          {
            "flex justify-center items-center": centered,
          },
        ]
      )}
      onClick={closeOnClickOutside ? onOverlayClick : undefined}
    >
      <div
        className={clsx("relative w-full rounded-2xl mx-auto bg-white p-6", [
          {
            "max-w-sm": size === "sm",
          },
          {
            "max-w-md": size === "md",
          },
          {
            "max-w-lg": size === "lg",
          },
          {
            "max-w-[536px]": size === "xl",
          },
          {
            "max-w-2xl": size === "2xl",
          },
          {
            "max-w-[940px]": size === "4xl",
          },
          {
            "max-w-[960px]": size === "5xl",
          },
          {
            "max-w-6xl": size === "6xl",
          },
          {
            "max-w-7xl": size === "7xl",
          },
          {
            "max-w-[1400px]": size === "8xl",
          },
          { "max-w-none": size === "full" },
        ])}
        ref={container}
      >
        {/* closer in the corner */}
        {closeButton && (
          <div className="flex justify-end">
            <button
              type="button"
              className="cursor-pointer"
              onClick={() => onClose()}
              title="close modal"
            >
              <img
                src={CLoseIcon}
                alt="close-icon"
                className="w-6 min-w-6 h-6"
              />
            </button>
          </div>
        )}
        {/* contents */}
        <div className="">{children}</div>
      </div>
    </div>
  );
};

const Head: React.FC<IHeadProps> = ({ children }) => (
  <div className="block">
    <h1 className="text-2xl text-primary-black font-bold text-center">
      {children}
    </h1>
  </div>
);

const Body: React.FC<IBodyProps> = ({ children }) => (
  <div className="">{children}</div>
);

export const Modal = { Frame, Head, Body };
