import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef, useState } from "react";
import { updateDiscountCommision } from "../../../utils/mutations";
import { FormikProps, useFormik } from "formik";
import { CommisionAndDiscountFormValuesType } from "../../../constants/types";
import { CommisionAndDiscountValues } from "../../../constants/data";
import { CommisionAndDiscountSchema } from "../../../constants/schema";
import { Input } from "../../core";
import { currencyFormatter } from "../../../utils/func";
import { Button } from "../../Button";
import { toast } from "react-toastify";

export const Finance = ({
  doctor,
  handleFetchAll,
  totalPaidAndTotalDue,
}: any) => {
  const [isEditable, setIsEditable] = useState<Boolean>(false);

  const updateCommissionAndDiscountMutation = useMutation({
    mutationKey: ["update-prescription-status"],
    mutationFn: updateDiscountCommision,
    onSuccess: (response) => {
      handleFetchAll();
      setIsEditable(false);
      toast.success(response?.data?.message);
    },
  });

  const formik: FormikProps<CommisionAndDiscountFormValuesType> =
    useFormik<CommisionAndDiscountFormValuesType>({
      validationSchema: CommisionAndDiscountSchema,
      initialValues: CommisionAndDiscountValues,
      onSubmit: (val: any) => handleUpdate(val),
    });

  const formikRef = useRef(formik);

  const handleUpdate = (val: any) => {
    const data = {
      id: doctor?.id,
      commission: val.commission,
      discount: val.discount,
    };
    updateCommissionAndDiscountMutation.mutate(data);
  };

  useEffect(() => {
    if (doctor) {
      formikRef?.current?.setFieldValue("commission", doctor?.commission);
      formikRef?.current?.setFieldValue("discount", doctor?.discount);
    }
  }, [doctor]);

  return (
    <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4 flex flex-col justify-between gap-6">
      <h2 className="text-primary-black text-2xl font-semibold">Finance</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-2 gap-x-4 gap-y-[38px] flex-1">
          <div className="flex flex-col gap-1">
            <span className="block text-dark-black text-sm font-medium">
              Total Paid
            </span>
            <h3 className="text-primary-blue text-2xl font-semibold">
              {currencyFormatter.format(totalPaidAndTotalDue?.payablePaid)}
            </h3>
          </div>
          <div className="flex flex-col gap-1">
            <span className="block text-dark-black text-sm font-medium">
              Total Dues
            </span>
            <h3 className="text-success text-2xl font-semibold">{`${currencyFormatter.format(
              totalPaidAndTotalDue?.payableDue
            )}`}</h3>
          </div>
          <div className="flex flex-col gap-1">
            <label className="block text-dark-black text-sm font-medium p-2 pb-0">
              Doctor Commission
            </label>
            <Input
              name="commission"
              formik={formik}
              type="number"
              placeholder="Commission"
              className={`text-base font-semibold text-primary-black placeholder:text-primary-black_05 w-full outline-none border-b border-transparent p-2 ${
                isEditable ? "focus:border-success" : ""
              }`}
              readOnly={!isEditable}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="block text-dark-black text-sm font-medium p-2 pb-0">
              Patient Discount
            </label>
            <Input
              name="discount"
              formik={formik}
              type="text"
              placeholder="Discount"
              className={`text-base font-semibold text-primary-black placeholder:text-primary-black_05 w-full outline-none border-b border-transparent p-2 ${
                isEditable ? "focus:border-success" : ""
              }`}
              readOnly={!isEditable}
            />
          </div>
        </div>
        <div className="flex justify-end">
          {isEditable ? (
            <div className="flex gap-2 pt-4">
              <Button
                label="CANCEL"
                onClick={() => setIsEditable((prev) => !prev)}
                type="button"
                className="text-base font-bold text-success py-2 px-4 border border-gray rounded-[20px] bg-transparent uppercase"
              />

              <Button
                loading={updateCommissionAndDiscountMutation.isPending}
                disabled={updateCommissionAndDiscountMutation.isPending}
                label="SAVE"
                type="submit"
                className="py-2 px-4 flex items-center justify-center rounded-[20px] bg-success uppercase text-white text-base font-bold"
              />
            </div>
          ) : (
            <div className="pt-4">
              <Button
                label="EDIT"
                onClick={() => setIsEditable((prev) => !prev)}
                type="button"
                className="py-2 px-4 flex items-center justify-center rounded-[20px] bg-success uppercase text-white text-base font-bold"
              />
            </div>
          )}
        </div>
      </form>
    </div>
  );
};
