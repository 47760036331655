import { FC, useEffect, useState } from "react";
import {
  PrescriptionCountCard,
  PrescriptionList,
} from "../../components/prescriptions";
import { PrescriptionHeader } from "../../components/prescriptions/PrescriptionHeader";
import { useDebouncedValue } from "../../hooks";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  GetAllPrescriptions,
  GetPrescriptionStatusCount,
} from "../../utils/query";
import { updatePrescriptionStatus } from "../../utils/mutations";
import { statusData } from "../../utils";
import moment from "moment";
import { useLoading } from "../../context/LoadingContext";
import { toast } from "react-toastify";

export const Prescriptions: FC = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [sort, setSort] = useState<string>("");
  const [filter, setFilter] = useState<string>("");
  const [selected, setSelected] = useState<number[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [debouncedSearchValue] = useDebouncedValue(search, 200);
  const { showLoading, hideLoading } = useLoading();

  const {
    data: prescriptionsData,
    isLoading: isPrescriptionsLoading,
    refetch: getAllPrescriptions,
  } = useQuery({
    queryKey: ["all-prescriptions"],
    queryFn: () =>
      GetAllPrescriptions({
        page: currentPage,
        limit: 10,
        search: debouncedSearchValue,
        status: sort,
        filter,
      }),
    enabled: false,
  });

  const {
    data: allPrescriptionCount,
    isLoading: isAllPrescriptionCountLoading,
    refetch: getAllPrescriptionsCount,
  } = useQuery({
    queryKey: ["all-prescription-count"],
    queryFn: GetPrescriptionStatusCount,
    enabled: false,
  });

  const updatePrescriptionStatusMutation = useMutation({
    mutationKey: ["update-prescription-status"],
    mutationFn: updatePrescriptionStatus,
    onSuccess: (response) => {
      toast.success(response?.data?.message);
      getAllPrescriptions();
      getAllPrescriptionsCount();
      setStatus("");
    },
  });

  const totalPages = prescriptionsData?.data?.totalPages ?? 1;

  const handlePageChange = (page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
  };

  const handleStatus = (value: string) => {
    setStatus(value);
    if (selected?.length && value) {
      updatePrescriptionStatusMutation.mutate({
        ids: selected,
        status: value,
      });
    }
  };

  const handleSortBy = (value: string) => {
    setSort(value);
  };

  const handleFilter = (value: string) => {
    setFilter(value);
  };

  const handleSelected = (event: any) => {
    const productId = parseInt(event.target.value); // Get the product ID from the value attribute
    const isChecked = event.target.checked;

    if (isChecked) {
      setSelected((prev) => [...prev, productId]);
    } else {
      setSelected((prev) => prev.filter((id) => id !== productId));
    }
  };

  const headers = [
    { key: "", label: "" }, // Checkbox
    { key: "id", label: "ID" },
    { key: "pr_id", label: "Prescription ID" },
    { key: "patient_name", label: "Patient Name" },
    { key: "mobile", label: "Mobile No." },
    { key: "name", label: "Sent By" },
    { key: "status", label: "Status" },
    { key: "createdAt", label: "Time" },
  ];

  const tableData = prescriptionsData?.data?.prescription?.map((t: any) => {
    return {
      id: t?.prescription_id,
      pr_id: t?.pr_id,
      patient_name: t?.patient_name,
      mobile: t?.mobile,
      name: `${t?.User?.firstname} ${t?.User?.lastname}`,
      status: t?.status,
      createdAt: moment(t?.createdAt).fromNow(),
    };
  });

  const isLoading = isPrescriptionsLoading || isAllPrescriptionCountLoading;

  useEffect(() => {
    if (isLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [isLoading, showLoading, hideLoading]);

  useEffect(() => {
    getAllPrescriptionsCount();
    getAllPrescriptions();
  }, []);

  useEffect(() => {
    getAllPrescriptions();
  }, [currentPage, getAllPrescriptions, search, filter, sort]);

  useEffect(() => {
    if (selectAll) {
      setSelected(
        prescriptionsData?.data?.prescription?.map(
          (pres: any) => pres.prescription_id
        )
      );
    } else {
      setSelected([]);
    }
  }, [prescriptionsData?.data?.prescription, selectAll]);

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="p-4 flex flex-col gap-4 h-full">
        <div className="grid grid-cols-7 gap-4">
          {allPrescriptionCount?.data?.length &&
            allPrescriptionCount?.data?.map((d: any, index: number) => (
              <PrescriptionCountCard
                key={index}
                status={d.status}
                title={d.status}
                count={d.count}
              />
            ))}
        </div>
        <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4 flex-1 flex flex-col">
          <PrescriptionHeader
            showStatus={!!selected?.length}
            search={search}
            selectedStatus={status}
            handleSearch={handleSearch}
            handleStatus={handleStatus}
            handleSortBy={handleSortBy}
            handleFilter={handleFilter}
            setSelectAll={setSelectAll}
          />
          <PrescriptionList
            headers={headers}
            data={tableData}
            currentPage={currentPage}
            totalPages={totalPages}
            selected={selected}
            handleSelected={handleSelected}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </div>
  );
};
