import { useNavigate } from "react-router-dom";
import { MainPages } from "../../../Router";
import { Fragment } from "react/jsx-runtime";
import { Pagination } from "../../Pagination";

interface PrescriptionRowProps {
  data: any;
  headers: any[];
  selected: number[];
  handleSelected: (e: any) => void;
  handleRedirection: () => void;
}

const PrescriptionRow: React.FC<PrescriptionRowProps> = ({
  data,
  headers,
  selected,
  handleSelected,
  handleRedirection,
}) => {
  return (
    <tr className={selected?.includes(data?.id) ? "active" : ""}>
      {headers?.map((head, index) => {
        return (
          <Fragment key={index}>
            {head.key === "" ? (
              <td>
                <div className="inline-flex items-center cursor-pointer relative">
                  <input
                    value={data?.id}
                    checked={selected?.includes(data?.id)}
                    type="checkbox"
                    className="peer h-6 w-6 cursor-pointer transition-all appearance-none rounded-lg border border-gray checked:bg-success checked:border-success"
                    id={`checkbox-${index}`}
                    onChange={handleSelected}
                  />
                  <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none">
                    <svg
                      width="16"
                      height="10"
                      viewBox="0 0 16 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 4.07683L5.21251 8.11305C5.42419 8.37902 5.81434 8.41618 6.07244 8.19496L14 1.3999"
                        stroke="white"
                        strokeWidth="2.4"
                        strokeLinecap="round"
                      />
                    </svg>
                  </span>
                </div>
              </td>
            ) : head.key === "status" ? (
              <td
                onClick={handleRedirection}
                className={`status-${data[head.key].toLowerCase()}`}
              >
                <span>{data[head.key] || ""}</span>
              </td>
            ) : head.key === "id" ? (
              <td className="hidden">
                <span>{data[head.key] || ""}</span>
              </td>
            ) : (
              <td onClick={handleRedirection}>
                <span>{data[head.key] || ""}</span>
              </td>
            )}
          </Fragment>
        );
      })}
    </tr>
  );
};

interface PrescriptionListProps {
  headers: any[];
  data: any[];
  currentPage: number;
  totalPages: number;
  selected: number[];
  onPageChange: (page: number) => void;
  handleSelected: (e: any) => void;
}

export const PrescriptionList: React.FC<PrescriptionListProps> = ({
  headers,
  data,
  currentPage,
  onPageChange,
  totalPages,
  selected,
  handleSelected,
}) => {
  const navigate = useNavigate();

  const getTableData = (data: any[]) => {
    return data?.map((tableRow) => {
      const row: any = {};

      headers.forEach(({ key }: any) => {
        if (key === "") {
          row[key] = false;
        } else {
          row[key] = tableRow[key] || "";
        }
      });

      return row;
    });
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex-1">
        <table>
          <thead>
            <tr>
              {headers?.map((head, index) => {
                if (head.key === "id") return null;
                return <th key={index}>{head?.label}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {getTableData(data)?.map((row, index) => {
              const handleRedirection = () => {
                navigate(`${MainPages.Prescriptions}/${row?.id}`);
              };
              return (
                <PrescriptionRow
                  key={index}
                  headers={headers}
                  selected={selected}
                  data={row}
                  handleSelected={handleSelected}
                  handleRedirection={handleRedirection}
                />
              );
            })}
          </tbody>
        </table>
      </div>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </div>
  );
};
