import DoctorIcon from "./../../../images/Doctor-icon.svg";
import CallIcon from "./../../../images/Call-icon.svg";
import RightArrow from "./../../../images/Right-arrow.svg";
import { useQuery } from "@tanstack/react-query";
import { GetRecentPrescription } from "../../../utils/query";
import { Chip } from "../../core";
import { useNavigate } from "react-router-dom";
import { MainPages } from "../../../Router";
import moment from "moment";
import { useEffect } from "react";
import { useLoading } from "../../../context/LoadingContext";

const statusColors = [
  {
    status: "open",
    color: "blue",
    title: "Open",
  },
  {
    status: "preparing",
    color: "yellow",
    title: "Preparing",
  },
  {
    status: "declined",
    color: "red",
    title: "Declined",
  },
  {
    status: "dispatch",
    color: "unnamed-0BC1E5",
    title: "Dispatch",
  },
  {
    status: "returned",
    color: "unnamed-F54E8E",
    title: "Returned",
  },
  {
    status: "delivered",
    color: "green",
    title: "Delivered",
  },
  {
    status: "closed",
    color: "primary-blue",
    title: "Closed",
  },
];

const PrescriptionCard = ({ prescription }: any) => {
  const navigate = useNavigate();
  const getStatus = (status: string): any => {
    return statusColors?.find((x) => x.status === status);
  };

  return (
    <div className="border border-gray hover:border-success rounded-2xl p-4">
      <span className="text-dark-black text-sm font-normal block">
        Patient Name
      </span>
      <span className="text-dark-black text-sm font-normal text-end block -mt-3">
        {moment(prescription?.createdAt).fromNow()}
      </span>
      <h3 className="text-primary-black text-xl font-semibold -mt-1">
        {prescription?.patient_name}
      </h3>
      <ul className="flex flex-col gap-4 pt-4">
        <li>
          <span className="text-base font-medium text-primary-black flex items-center gap-2">
            <span className="w-6 h-6 flex items-center justify-center">
              <img src={DoctorIcon} alt="DoctorIcon" />
            </span>
            {`${prescription?.User?.firstname} ${prescription?.User?.lastname}`}
          </span>
        </li>
        <li>
          <span className="text-base font-medium text-primary-black flex items-center gap-2">
            <span className="w-6 h-6 flex items-center justify-center">
              <img src={CallIcon} alt="CallIcon" />
            </span>
            {prescription?.mobile}
          </span>
        </li>
      </ul>
      <div className="flex items-center justify-between pt-5">
        <Chip
          color={getStatus(prescription?.status)?.color}
          title={getStatus(prescription?.status)?.title}
        />
        <button
          type="button"
          className="w-6 h-6 flex items-center justify-center"
          onClick={() =>
            navigate(
              `${MainPages.Prescriptions}/${prescription?.prescription_id}`
            )
          }
        >
          <img src={RightArrow} alt="Right arrow" />
        </button>
      </div>
    </div>
  );
};

export const RecentPrescription = () => {
  const { data: recentPrescriptions, isLoading } = useQuery({
    queryKey: ["recent-prescription"],
    queryFn: GetRecentPrescription,
  });

  const { showLoading, hideLoading } = useLoading(); // Access loading context

  useEffect(() => {
    if (isLoading) {
      showLoading();
    } else {
      hideLoading();
    }
  }, [isLoading, showLoading, hideLoading]);

  return (
    <div className="grid grid-cols-4 gap-4 pt-6">
      {!isLoading &&
        recentPrescriptions?.data?.prescription?.map(
          (prescription: any, i: number) => (
            <PrescriptionCard prescription={prescription} key={i} />
          )
        )}
    </div>
  );
};
