import React from "react";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Chart, registerables } from "chart.js";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
Chart.register(...registerables, ChartDataLabels);

// Register necessary components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// Define the structure of amountData
interface AmountData {
  id: number;
  amount: number;
}

// Define props for the FinancialGraph component
interface FinancialGraphProps {
  amountData: AmountData[];
}

const FinancialGraph: React.FC<FinancialGraphProps> = ({ amountData }) => {
  // Prepare data for the chart
  const data = {
    labels: [
      "", //"Total Payment",
      "", //"Paid to Doctors",
      "", // "Discount to Patient",
      "", //"Pending Dues",
    ],
    datasets: [
      {
        label: "Financial Data",
        data: amountData.map((item) => item.amount), // Extract amounts from the data
        backgroundColor: ["#4CAF50", "#3F51B5", "#FFC107", "#F44336"],
        borderRadius: 18,
        borderColor: "#F44336",
        borderWidth: 0,
      },
    ],
  };

  // Specify options with the correct type
  const options: ChartOptions<"bar"> = {
    indexAxis: "y", // Set the indexAxis to 'y' for horizontal bars
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        anchor: "end", // Position the label at the end of the bar
        align: "start", // Align the label to the end
        offset: 10,
        formatter: (value) => value, // Show the value
        color: "white", // Text color
        font: {
          weight: "bold", // Font weight
          size: 12, // Font size
          lineHeight: "10px",
        },
      },
      legend: {
        display: false,
      },
    },
    layout: {
      padding: 0,
    },
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          drawOnChartArea: false,
          lineWidth: 0,
          display: true,
          tickBorderDash: [0],
          tickWidth: 0,
          offset: false,
        },
        ticks: {
          color: "#405152",
          font: {
            size: 12,
            weight: "normal",
          },
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          autoSkip: false, // Show all y-axis labels
        },
        grid: {
          drawOnChartArea: false,
          lineWidth: 0,
        },
      },
    },
  };

  return (
    <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4 flex flex-col gap-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-8 mt-2">
          <h3 className="text-primary-black text-base font-bold">
            Financial Graph
          </h3>
          <div className="flex items-center gap-4 ">
            <div className="flex">
              <div className="flex items-center mr-4">
                <span className="w-3 h-3 bg-[#4CAF50] rounded-full mr-1"></span>
                <span>Total Payment</span>
              </div>
              <div className="flex items-center mr-4">
                <span className="w-3 h-3 bg-[#3F51B5] rounded-full mr-1"></span>
                <span>Paid to Doctors</span>
              </div>
              <div className="flex items-center mr-4">
                <span className="w-3 h-3 bg-[#FFC107] rounded-full mr-1"></span>
                <span>Discount to Patient</span>
              </div>
              <div className="flex items-center">
                <span className="w-3 h-3 bg-[#F44336] rounded-full mr-1"></span>
                <span>Pending Dues</span>
              </div>
            </div>
          </div>
        </div>
        <div className="relative bg-down-arrow bg-no-repeat bg-[100%_65%]">
          <select className="appearance-none outline-none cursor-pointer pe-6 bg-transparent text-success text-sm font-bold">
            <option value="">Today</option>
          </select>
        </div>
      </div>

      <div className="h-[200px] w-full">
        <Bar data={data} options={options} className="FinancialGraph" />
      </div>
      {/* <ul className="flex items-center justify-between">
        {amountData.map((item) => (
          <li key={item.id} className="text-xs font-normal text-dark-black">
            {item.amount}
          </li>
        ))}
      </ul> */}
    </div>
  );
};

export default FinancialGraph;
