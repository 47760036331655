import { useNavigate } from "react-router-dom";
import { useDisclosure } from "../../../hooks";
import { DeliveryBoyCreateUpdateModal } from "../DeliveryBoyCreateUpdateModal";
import CallIcon from "./../../../images/Call-icon.svg";
import EditIcon from "./../../../images/Edit-Icon.svg";
import moment from "moment";
import { useState } from "react";
import { MainPages } from "../../../Router";
import { Chip } from "../../core";

export const DeliveryBoyList = ({
  isLoading,
  deliveryBoydata,
  getDeliveryBoyList,
}: any) => {
  const [isDeliveryModalOpen, deliveryModalHandler] = useDisclosure(false);
  const [did, setDid] = useState(null);
  const navigate = useNavigate();

  return (
    <>
      <div className="grid grid-cols-4 gap-4">
        {!isLoading &&
          deliveryBoydata?.data?.deliveryBoy?.map(
            (items: any, index: number) => (
              <div
                key={index}
                className="border border-gray hover:border-success rounded-2xl p-4 flex flex-col gap-6"
              >
                <div className="flex flex-col gap-1">
                  <div className="flex justify-between">
                    <span
                      className="text-dark-black text-sm font-normal capitalize"
                      onClick={() =>
                        navigate(`${MainPages.Delivery}/${items?.d_id}`)
                      }
                      role="button"
                    >
                      Delivery Boy Name
                    </span>
                    <button
                      type="button"
                      onClick={() => {
                        setDid(items?.d_id);
                        deliveryModalHandler.open();
                      }}
                    >
                      <img
                        src={EditIcon}
                        alt="EditIcon"
                        className="w-6 min-w-6 h-6"
                      />
                    </button>
                  </div>
                  <h3
                    onClick={() =>
                      navigate(`${MainPages.Delivery}/${items?.d_id}`)
                    }
                    role="button"
                    className="text-xl text-primary-black font-semibold"
                  >
                    {items.name}
                  </h3>
                </div>
                <div
                  onClick={() =>
                    navigate(`${MainPages.Delivery}/${items?.d_id}`)
                  }
                  role="button"
                  className="text-base font-medium text-primary-black flex items-center gap-2"
                >
                  <span className="w-6 h-6 flex items-center justify-center">
                    <img src={CallIcon} alt="CallIcon" />
                  </span>
                  {items.mobile}
                </div>
                <div
                  onClick={() =>
                    navigate(`${MainPages.Delivery}/${items?.d_id}`)
                  }
                  role="button"
                  className="flex justify-between gap-2 items-center"
                >
                  <p className="text-sm text-dark-black font-normal">
                    {`Joined on ${moment(items?.createdAt).format(
                      "MMM D, YYYY"
                    )}`}
                  </p>
                  <Chip
                    color="unnamed-0BC1E5"
                    title={`Dispatch ${items?.dispatchCount}`}
                  />
                </div>
              </div>
            )
          )}
      </div>
      <DeliveryBoyCreateUpdateModal
        getDeliveryBoyList={getDeliveryBoyList}
        title={"Update Details"}
        id={did}
        isDeliveryModalOpen={isDeliveryModalOpen}
        deliveryModalHandler={deliveryModalHandler}
      />
    </>
  );
};
