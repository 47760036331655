import React from "react";
import { Priority } from "../../constants/types";
import { priorityColors } from "../../utils";

type PriorityDotProps = {
  priority: Priority;
};

export const PriorityDot: React.FC<PriorityDotProps> = ({ priority }) => {
  return (
    <span
      className={`w-3 h-3 rounded-full inline-block ${
        `bg-${priorityColors[priority]}` || "bg-gray"
      }`}
    ></span>
  );
};

export default PriorityDot;
