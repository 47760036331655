import { useMutation } from "@tanstack/react-query";
import { Modal } from "../Modal";
import OTPVerification from "../OTPVerification";
import { verifyAdminOtp } from "../../utils/mutations";
import { FormikProps, useFormik } from "formik";
import { VerifyOtpValuesType } from "../../constants/types";
import { VerifyOtpSchema } from "../../constants/schema";
import { verifyOtpValues } from "../../constants/data";
import { useDispatch } from "react-redux";
import { setIsAuthorized } from "../../redux/userSlice";
import { Button } from "../Button";
import { toast } from "react-toastify";

export const OtpVerificationModal = ({
  verificationModalHandler,
  adminEmail,
  setAdminEmail,
  setIsOtpSend,
}: any) => {
  const dispatch = useDispatch();

  const mutation = useMutation({
    mutationKey: ["admin-verify-otp"],
    mutationFn: verifyAdminOtp,
    onSuccess: (response) => {
      toast.success(response?.data?.message);
      dispatch(setIsAuthorized(true));
      setAdminEmail("");
      setIsOtpSend(false);
      verificationModalHandler.close();
    },
    onError: (error) => {
      console.log("error", error);
    },
  });

  const formik: FormikProps<VerifyOtpValuesType> =
    useFormik<VerifyOtpValuesType>({
      validationSchema: VerifyOtpSchema,
      initialValues: verifyOtpValues,
      onSubmit: (val: any) => handleFormSubmit(val),
    });

  const handleFormSubmit = (val: any) => {
    if (adminEmail) {
      const data = {
        email: adminEmail,
        otp: val.otp,
      };
      mutation.mutate(data);
    }
  };
  return (
    <div className="h-screen fixed p-4 top-0 right-0 z-50 w-full max-w-[calc(100%-256px)] rounded-2xl">
      <div className="h-full backdrop-blur-[60px] bg-white_054 rounded-2xl overflow-hidden flex flex-col justify-center items-center">
        <div className="w-full max-w-[648px] mx-auto">
          <div className="flex flex-col gap-12">
            <div className="flex flex-col gap-4">
              <h1 className="text-[40px] font-bold text-primary-black">
                OTP Verification
              </h1>
              <p className="text-base font-normal text-dark-black">
                OTP sent to given email address
              </p>
            </div>
            <form
              action=""
              onSubmit={formik.handleSubmit}
              className="flex flex-col gap-12"
            >
              <div className="flex flex-col">
                <OTPVerification name="otp" label="Enter OTP" formik={formik} />
              </div>
              <div className="">
                <Button
                  disabled={mutation.isPending}
                  loading={mutation.isPending}
                  label="Verify"
                  type="submit"
                  className="text-base uppercase font-bold text-white p-3 w-full rounded-3xl bg-success"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
