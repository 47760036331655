import { FC } from "react";

interface IChiProps {
  title: string;
  color?: string;
}

export const Chip: FC<IChiProps> = ({ title, color = "blue" }) => {
  return (
    <span
      className={`capitalize text-xs font-semibold text-${color} border border-${color} rounded-[30px] py-1 px-2`}
    >
      {title}
    </span>
  );
};
