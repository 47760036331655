import { FormikProps } from "formik";
import { FC, useState } from "react";

interface ITextAreaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  formik: FormikProps<any>;
}

export const TextArea: FC<ITextAreaProps> = ({
  name,
  label,
  formik,
  ...props
}) => {
  const [focus, setFocus] = useState<boolean>(false);
  const { handleBlur, handleChange, values, errors, touched } = formik;

  return (
    <div className="relative">
      {label && (
        <label
          htmlFor={name}
          className="text-dark-black text-sm font-normal block my-2 ms-2"
        >
          {label}
          <span className="text-success">*</span>
        </label>
      )}
      <textarea
        name={name}
        id={name}
        className="text-primary-black font-semibold text-base placeholder:text-primary-black_05 placeholder:font-normal w-full border-b border-gray focus-within:outline-none p-2 pt-0 resize-none"
        onChange={handleChange}
        onBlur={() => {
          handleBlur(name);
          setFocus(false);
        }}
        onFocus={() => setFocus(true)}
        value={name ? values[name] : ""}
        {...props}
      />
      {name
        ? errors[name] &&
          touched[name] && (
            <span className="text-red">{errors[name] as string}</span>
          )
        : null}
    </div>
  );
};
