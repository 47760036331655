import { useMutation, useQuery } from "@tanstack/react-query";
import { Modal } from "../../Modal";
import { GetDeliveryBoyDetails } from "../../../utils/query";
import { useEffect, useRef } from "react";
import { FormikProps, useFormik } from "formik";
import { DeliveryBoyFormValuesType } from "../../../constants/types";
import { DeliveryBoySchema } from "../../../constants/schema";
import { DeliveryBoyValues } from "../../../constants/data";
import { Input } from "../../core";
import { createDeliveryBoy, updateDeliveryBoy } from "../../../utils/mutations";
import { Button } from "../../Button";
import { toast } from "react-toastify";

export const DeliveryBoyCreateUpdateModal = ({
  id,
  title,
  isDeliveryModalOpen,
  deliveryModalHandler,
  getDeliveryBoyList,
}: any) => {
  const {
    data: deliveryBoyData,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["delivery-boy-details"],
    queryFn: () => GetDeliveryBoyDetails(id),
    enabled: false,
  });

  useEffect(() => {
    if (id) {
      console.log("id", id);
      refetch();
    }
  }, [id]);

  const createDeliveryBoyMutation = useMutation({
    mutationKey: ["create-delivery-boy"],
    mutationFn: createDeliveryBoy,
    onSuccess: (response) => {
      toast.success(response?.data?.message);
      getDeliveryBoyList();
      deliveryModalHandler.close();
    },
  });

  const updateDeliveryBoyMutation = useMutation({
    mutationKey: ["update-delivery-boy"],
    mutationFn: updateDeliveryBoy,
    onSuccess: (response) => {
      toast.success(response?.data?.message);
      getDeliveryBoyList();
      deliveryModalHandler.close();
    },
  });

  const formik: FormikProps<DeliveryBoyFormValuesType> =
    useFormik<DeliveryBoyFormValuesType>({
      validationSchema: DeliveryBoySchema,
      initialValues: DeliveryBoyValues,
      onSubmit: (val: any) => handleDeliveryBoy(val),
    });

  const formikRef = useRef(formik);

  const handleDeliveryBoy = (val: any) => {
    if (id) {
      const data = {
        id,
        name: val.name,
        mobile: val.mobile,
      };
      updateDeliveryBoyMutation.mutate(data);
    } else {
      const data = {
        name: val.name,
        mobile: val.mobile,
      };

      createDeliveryBoyMutation.mutate(data);
    }
  };

  useEffect(() => {
    if (deliveryBoyData) {
      formikRef.current.setFieldValue(
        "name",
        deliveryBoyData?.data?.deliveryBoy?.name
      );
      formikRef.current.setFieldValue(
        "mobile",
        deliveryBoyData?.data?.deliveryBoy?.mobile
      );
    }
  }, [deliveryBoyData]);

  return (
    <Modal
      open={isDeliveryModalOpen}
      onClose={deliveryModalHandler.close}
      title={title}
      centered
      size="5xl"
    >
      <div className="pt-10 w-full max-w-[720px] mx-auto">
        <form className="flex flex-col gap-6" onSubmit={formik.handleSubmit}>
          <div className="">
            <label
              htmlFor=""
              className="block p-2 pb-0 text-sm font-normal text-dark-black"
            >
              Name <span className="text-success">*</span>
            </label>
            <Input
              formik={formik}
              name="name"
              type="text"
              placeholder="Name"
              className="text-base font-semibold w-full text-primary-black placeholder:text-primary-black_05 outline-none border-b border-gray p-2"
            />
          </div>
          <div className="">
            <label
              htmlFor=""
              className="block p-2 pb-0 text-sm font-normal text-dark-black"
            >
              Mobile Number <span className="text-success">*</span>
            </label>
            <Input
              formik={formik}
              name="mobile"
              type="tel"
              placeholder="Mobile Number"
              className="text-base font-semibold w-full text-primary-black placeholder:text-primary-black_05 outline-none border-b border-gray p-2"
            />
          </div>
          <div className="flex justify-end py-6">
            <Button
              label={id ? "Update" : "Add"}
              disabled={
                createDeliveryBoyMutation.isPending ||
                updateDeliveryBoyMutation.isPending
              }
              loading={
                createDeliveryBoyMutation.isPending ||
                updateDeliveryBoyMutation.isPending
              }
              type="submit"
              className="uppercase text-base font-bold text-white bg-success rounded-[20px] py-2 px-4"
            />
          </div>
        </form>
      </div>
    </Modal>
  );
};
