"use client";

import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { getQueryClient } from "./getQueryClient";

interface Props {
  children: React.ReactNode;
}

const queryClient = getQueryClient();
const isOpenDevtool = process.env.NEXT_PUBLIC_ENV === "development";

export default function QueryWrapper({ children }: Props) {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {isOpenDevtool && <ReactQueryDevtools />}
    </QueryClientProvider>
  );
}
