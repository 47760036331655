import { useSelector } from "react-redux";
import { useDisclosure } from "../../hooks";
import { RootState } from "../../redux/store";
import { AuthorizationModal } from "../../components";
import { OtpVerificationModal } from "../../components/OtpVerificationModal";
import { useEffect, useState } from "react";

export const Authorize = () => {
  const [isAuthorizationModalOpen, authorizationModalHandler] =
    useDisclosure(false);
  const [isVerificationModalOpen, verificationModalHandler] =
    useDisclosure(false);
  const isAuthorized = useSelector(
    (state: RootState) => state.user.isAuthorized
  );
  const [isOtpSend, setIsOtpSend] = useState<boolean>(false);
  const [adminEmail, setAdminEmail] = useState<string>("");

  useEffect(() => {
    if (!isAuthorized && !isOtpSend) {
      authorizationModalHandler.open();
    }
  }, [authorizationModalHandler, isAuthorized, isOtpSend]);

  useEffect(() => {
    if (!isAuthorized && isOtpSend) {
      verificationModalHandler.open();
    }
  }, [isAuthorized, isOtpSend, verificationModalHandler]);

  return (
    <>
      {!isAuthorized && isAuthorizationModalOpen && (
        <AuthorizationModal
          authorizationModalHandler={authorizationModalHandler}
          setIsOtpSend={setIsOtpSend}
          setAdminEmail={setAdminEmail}
        />
      )}
      {!isAuthorized && isVerificationModalOpen && (
        <OtpVerificationModal
          verificationModalHandler={verificationModalHandler}
          adminEmail={adminEmail}
          setAdminEmail={setAdminEmail}
          setIsOtpSend={setIsOtpSend}
        />
      )}
    </>
  );
};
