import { FC, useState } from "react";
import { FormikProps } from "formik";

interface ISearchInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  formik?: FormikProps<any>;
}

export const SearchInput: FC<ISearchInputProps> = ({
  name,
  label,
  type = "text",
  formik,
  ...props
}) => {
  const [localValue, setLocalValue] = useState<string>("");
  const [focus, setFocus] = useState<boolean>(false);

  // Handle change based on formik or local state
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (formik) {
      formik.handleChange(e);
    } else {
      setLocalValue(e.target.value);
    }
  };

  const handleBlur = () => {
    if (formik && name) {
      formik.handleBlur(name);
    }
    setFocus(false);
  };

  const value = formik && name ? formik.values[name] : localValue;

  const error = formik && name && formik.errors[name];
  const touched = formik && name && formik.touched[name];

  return (
    <div className="relative">
      {label && (
        <label
          htmlFor={name}
          className="text-dark-black text-sm font-normal block my-2 ms-2"
        >
          {label}
          <span className="text-success">*</span>
        </label>
      )}
      <input
        type={type}
        name={name}
        id={name}
        className="text-primary-black font-semibold text-base placeholder:text-primary-black w-full border-b border-gray focus-within:outline-none p-2 pt-0"
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={() => setFocus(true)}
        value={value}
        {...props}
      />
      {error && touched && <span className="text-red">{error as string}</span>}
      <span className="absolute top-1/2 right-4 -translate-y-1/2 pointer-events-none">
        <svg
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.62793 0.0617371C3.62656 0.603315 0.510558 3.79691 0.0334878 7.83295C0.00340225 8.10374 -0.00949153 8.58944 0.00770018 9.14391C0.0377857 10.3173 0.192511 11.0867 0.605112 12.1269C1.56785 14.5683 3.56639 16.4509 6.05918 17.2676C7.00473 17.5771 7.79555 17.7017 8.83564 17.7017C10.6838 17.7017 12.2998 17.216 13.8298 16.1887L14.2768 15.8878L17.2467 18.8579C19.5246 21.1317 20.2595 21.8452 20.41 21.9097C20.6764 22.03 21.132 22.03 21.3813 21.9097C21.5919 21.8108 21.8541 21.54 21.94 21.3294C22.0303 21.1145 22.0174 20.6417 21.9099 20.4096C21.8455 20.2592 21.1363 19.5285 18.8584 17.2461L15.8885 14.276L16.1636 13.872C16.9415 12.7329 17.4616 11.3833 17.6378 10.0293C17.7195 9.39321 17.7195 8.30146 17.6378 7.67821C17.2381 4.63506 15.2782 2.00024 12.4932 0.758051C11.6852 0.396999 10.9459 0.186386 10.0305 0.0574379C9.49323 -0.0199299 8.20385 -0.0199299 7.62793 0.0617371ZM10.1594 2.3484C11.5047 2.64068 12.5319 3.20375 13.5161 4.18375C14.135 4.80269 14.4745 5.2712 14.8227 5.98901C16.2453 8.94189 15.2568 12.5395 12.5233 14.3577C11.4617 15.0626 10.3786 15.4064 9.08492 15.4537C7.11647 15.5225 5.42309 14.8262 4.03056 13.3734C3.18817 12.4922 2.62084 11.4134 2.33718 10.1368C2.22973 9.6511 2.19964 8.41751 2.2856 7.87593C2.60795 5.86865 3.76839 4.16655 5.54773 3.10919C6.18382 2.73094 7.052 2.42577 7.88151 2.28822C8.38006 2.20656 9.65655 2.24094 10.1594 2.3484Z"
            fill="#405152"
          />
        </svg>
      </span>
    </div>
  );
};
