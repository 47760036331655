export * from "./dashboard";
export * from "./login";
export * from "./register";
export * from "./resetPassword";
export * from "./notification";
export * from "./doctor";
export * from "./doctorDetails";
export * from "./prescriptions";
export * from "./prescriptionsDetails";
export * from "./delivery";
export * from "./billyRivera";
export * from "./financial";
export * from "./newDoctor";
export * from "./financialDoctors";
