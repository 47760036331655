import { useSelector } from "react-redux";
import { Authorize, DoctorListContainer } from "../../container";
import { RootState } from "../../redux/store";

export const FinancialDoctors = () => {
  const isAuthorized = useSelector(
    (state: RootState) => state.user.isAuthorized
  );
  return (
    <>
      {isAuthorized && <DoctorListContainer />}
      <Authorize />
    </>
  );
};
