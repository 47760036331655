// Button.tsx
import { FC, ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: React.ReactNode;
  loading?: boolean;
}

export const Button: FC<ButtonProps> = ({
  label,
  className = "",
  disabled = false,
  loading = false,
  ...props
}) => {
  return (
    <button
      type="submit"
      className={`
        ${
          disabled || loading
            ? "bg-gray-400 opacity-50 cursor-not-allowed"
            : "bg-success"
        }
        ${className} transition transform active:scale-95`}
      disabled={disabled || loading}
      {...props}
    >
      {loading ? (
        <div className="flex justify-center gap-2">
          <svg
            className="animate-spin h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zM12 4a8 8 0 018 8h4c0-6.627-5.373-12-12-12v4z"
            ></path>
          </svg>
          <span>{label}</span>
        </div>
      ) : (
        label
      )}
    </button>
  );
};
