import React, { useState } from "react";

export const Avatar = ({
  src,
  name,
  alt = "User Avatar",
  size = 50,
  className = "",
}: any) => {
  const [imgSrc, setImgSrc] = useState(src);

  const handleError = () => {
    setImgSrc(null);
  };

  const renderInitials = () => {
    if (name && typeof name === "string") {
      const nameParts = name.split(" ");

      const initials = nameParts
        .slice(0, 2)
        .map((part: string) => part[0]?.toUpperCase())
        .join("");
      return initials || "?";
    }
    return "?";
  };

  return (
    <div
      className={`flex items-center justify-center border bg-gray-300 text-white rounded-full overflow-hidden text-xl font-semibold bg-success ${className}`}
      style={{
        width: size,
        height: size,
      }}
    >
      {imgSrc ? (
        <img
          src={imgSrc}
          alt={alt}
          onError={handleError}
          className="w-full h-full object-cover rounded-full"
        />
      ) : (
        <span className={`text-${size / 3}px`}>{renderInitials()}</span>
      )}
    </div>
  );
};
