import React, { Dispatch, FC } from "react";
import { useDisclosure } from "../../../hooks";
import { ChipWithSelect } from "../../core/ChipWithSelect";
import { SearchInput } from "../../core";
import { SortBy } from "../SortBy";

interface IPrescriptionHeaderProps {
  search: string;
  selectedStatus: string;
  showStatus: boolean;
  excludeStatus?: string[];
  handleSearch: (value: string) => void;
  handleStatus: (value: string) => void;
  handleSortBy: (value: string) => void;
  handleFilter: (value: string) => void;
  setSelectAll: Dispatch<React.SetStateAction<boolean>>;
}

export const PrescriptionHeader: FC<IPrescriptionHeaderProps> = ({
  search,
  selectedStatus,
  showStatus,
  excludeStatus = [],
  handleSearch,
  handleStatus,
  handleFilter,
  handleSortBy,
  setSelectAll,
}) => {
  const statusData = [
    {
      title: "Open",
      color: "blue",
      status: "open",
    },
    {
      title: "Preparing",
      color: "yellow",
      status: "preparing",
    },
    {
      title: "Decline",
      color: "red",
      status: "declined",
    },
    {
      title: "Dispatch",
      color: "unnamed-0BC1E5",
      status: "dispatch",
    },
    {
      title: "Delivered",
      color: "green",
      status: "delivered",
    },
    {
      title: "Return",
      color: "unnamed-F54E8E",
      status: "return",
    },
    {
      title: "Closed",
      color: "primary-blue",
      status: "closed",
    },
  ];

  const [isFilterOpen, FilterOpenHandler] = useDisclosure(false);

  return (
    <div className="flex items-center justify-between ps-[18px]">
      <div className="flex items-center gap-6">
        <div className="flex items-center gap-4">
          <div className="flex items-center cursor-pointer relative">
            <input
              type="checkbox"
              className="peer h-6 w-6 cursor-pointer transition-all appearance-none rounded-lg border border-gray checked:bg-success checked:border-success"
              id="all-check"
              onClick={() => setSelectAll((prev) => !prev)}
            />
            <span className="absolute text-white opacity-0 peer-checked:opacity-100 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="4"
                viewBox="0 0 15 4"
                fill="none"
              >
                <path
                  d="M2 1.99994H13"
                  stroke="white"
                  stroke-width="2.4"
                  stroke-linecap="round"
                />
              </svg>
            </span>
          </div>
          <label
            htmlFor="all-check"
            className="text-dark-black text-base font-semibold"
          >
            Select All
          </label>
        </div>
        {showStatus && (
          <div className="flex gap-2">
            {statusData?.map((status: any, index: number) => {
              if (!excludeStatus?.includes(status.status)) {
                return (
                  <ChipWithSelect
                    key={index}
                    selected={selectedStatus === status.status}
                    color={status?.color}
                    title={status?.title}
                    onClick={() => handleStatus(status.status)}
                  />
                );
              }
            })}
          </div>
        )}
      </div>
      <div className="flex items-center gap-6">
        <div className="relative">
          <SearchInput
            value={search}
            onChange={(e) => handleSearch(e.target.value)}
            type="search"
            name="searchPrescription"
            placeholder="Search here..."
            className="border border-gray rounded-lg py-2.5 ps-4 pe-10 min-w-[348px] outline-none placeholder:text-dark-black text-sm font-normal text-primary-black"
          />
          <span className="absolute top-1/2 right-4 -translate-y-1/2 pointer-events-none">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.62793 0.0617371C3.62656 0.603315 0.510558 3.79691 0.0334878 7.83295C0.00340225 8.10374 -0.00949153 8.58944 0.00770018 9.14391C0.0377857 10.3173 0.192511 11.0867 0.605112 12.1269C1.56785 14.5683 3.56639 16.4509 6.05918 17.2676C7.00473 17.5771 7.79555 17.7017 8.83564 17.7017C10.6838 17.7017 12.2998 17.216 13.8298 16.1887L14.2768 15.8878L17.2467 18.8579C19.5246 21.1317 20.2595 21.8452 20.41 21.9097C20.6764 22.03 21.132 22.03 21.3813 21.9097C21.5919 21.8108 21.8541 21.54 21.94 21.3294C22.0303 21.1145 22.0174 20.6417 21.9099 20.4096C21.8455 20.2592 21.1363 19.5285 18.8584 17.2461L15.8885 14.276L16.1636 13.872C16.9415 12.7329 17.4616 11.3833 17.6378 10.0293C17.7195 9.39321 17.7195 8.30146 17.6378 7.67821C17.2381 4.63506 15.2782 2.00024 12.4932 0.758051C11.6852 0.396999 10.9459 0.186386 10.0305 0.0574379C9.49323 -0.0199299 8.20385 -0.0199299 7.62793 0.0617371ZM10.1594 2.3484C11.5047 2.64068 12.5319 3.20375 13.5161 4.18375C14.135 4.80269 14.4745 5.2712 14.8227 5.98901C16.2453 8.94189 15.2568 12.5395 12.5233 14.3577C11.4617 15.0626 10.3786 15.4064 9.08492 15.4537C7.11647 15.5225 5.42309 14.8262 4.03056 13.3734C3.18817 12.4922 2.62084 11.4134 2.33718 10.1368C2.22973 9.6511 2.19964 8.41751 2.2856 7.87593C2.60795 5.86865 3.76839 4.16655 5.54773 3.10919C6.18382 2.73094 7.052 2.42577 7.88151 2.28822C8.38006 2.20656 9.65655 2.24094 10.1594 2.3484Z"
                fill="#405152"
              />
            </svg>
          </span>
        </div>
        <div className="relative flex">
          <button type="button" onClick={FilterOpenHandler.toggle}>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.7268 3.05582C16.1813 3.3935 14.8883 4.57008 14.4041 6.08433L14.3165 6.35869L8.01637 6.36924L1.7162 6.38507L1.48954 6.54335C0.788949 7.04986 0.850766 8.13147 1.60802 8.52718C1.79862 8.62215 1.96862 8.62743 8.05243 8.62743H14.3011L14.4453 9.04425C14.8986 10.3633 15.9701 11.3974 17.3404 11.8459C17.8246 12.0042 18.9683 12.0516 19.5092 11.9356C21.2143 11.5662 22.5588 10.1892 22.9348 8.44277C23.0327 7.98374 23.0173 6.8863 22.9091 6.46421C22.6721 5.53033 22.3527 4.96051 21.6985 4.30099C21.0494 3.64675 20.5497 3.35129 19.7049 3.10859C19.2876 2.99251 18.1646 2.96085 17.7268 3.05582ZM19.4886 5.48285C19.9367 5.69917 20.3179 6.08961 20.5394 6.54335C21.1782 7.86767 20.39 9.41358 18.9219 9.69321C18.1234 9.84622 17.1653 9.36609 16.7274 8.60105C16.0577 7.42975 16.6192 5.88911 17.8968 5.40371C18.3089 5.24542 19.0919 5.28236 19.4886 5.48285Z"
                fill="#405152"
              />
              <path
                d="M4.44752 12.0645C2.8196 12.449 1.5523 13.6919 1.11956 15.3404C0.970164 15.904 0.959861 17.01 1.0938 17.5367C1.31017 18.3794 1.63988 18.9903 2.19625 19.5855C4.32903 21.866 7.97638 21.3077 9.35187 18.4952C9.43945 18.3214 9.54763 18.0528 9.594 17.9054L9.68673 17.6262L15.9872 17.6157L22.2876 17.5999L22.5143 17.4419C23.2098 16.9363 23.1479 15.8566 22.3958 15.4668C22.2052 15.3615 22.0661 15.3615 15.9511 15.3615H9.70218L9.57339 14.9823C9.1355 13.6656 8.0382 12.6017 6.66272 12.1487C6.20422 11.996 4.93692 11.9486 4.44752 12.0645ZM5.823 14.3134C7.20364 14.6083 7.95578 16.162 7.33758 17.4419C7.11606 17.8948 6.73484 18.2846 6.28665 18.5005C5.90028 18.6954 5.1945 18.7375 4.77722 18.6006C3.91175 18.3162 3.31416 17.5419 3.25234 16.6361C3.18021 15.5511 3.83962 14.6294 4.90086 14.3345C5.23572 14.2449 5.47269 14.2397 5.823 14.3134Z"
                fill="#405152"
              />
            </svg>
          </button>
          {isFilterOpen && (
            <SortBy handleSortBy={handleSortBy} handleFilter={handleFilter} />
          )}
        </div>
      </div>
    </div>
  );
};
