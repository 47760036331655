export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const ENDPOINTS = {
  ADMIN_DETAILS: "/admin-details",
  ADMIN_LOGIN: "/admin-login",
  ADMIN_CHANGE_PASSWORD: "/admin-change-password",
  ADMIN_RESET_PASSWORD: "/admin-reset-password",
  GET_COUNTS: "/dashboard/get-doctor-and-prescriptions-counts",
  RECENT_PRESCRIPTION: "/dashboard/recent-prescriptions",
  REQUIRE_DETAILS_DOCTORS: "//dashboard/require-details-doctor-list",
  PRESCRIPTION_DETAILS: "/prescription/details/",
  DELIVERY_BOY_DETAILS: "/delivery-boy/",
  DELIVERY_BOY_LIST: "/delivery-boy/all/",
  GET_PRESCRIPTION_STATUS_COUNT: "/dashboard/all-prescription-count/",
  GET_PRESCRIPTION_STATUS_COUNT_BY_DOCTOR:
    "/dashboard/all-prescription-count-by-doctor/",
  DOCTOR_DETAILS: "/doctor/doctor-details/",
  GET_PRESCRIPTION_BY_DOCTOR: "/doctor/doctor-prescription",
  GET_PRESCRIPTION_BY_DELIVERY_BOY: "/delivery-boy/prescription/delivery-boy",
  GET_PRESCRIPTION_STATUS_COUNT_BY_DELIVERY_BOY:
    "/dashboard/all-prescription-count-by-delivery-boy/",
  TOTAL_PAID_AND_TOTAL_DUE: "/doctor/total-paid-and-total-due/",
  DELIVERY_BOY_CREATE: "/delivery-boy/",
  DELIVERY_BOY_UPDATE: "/delivery-boy/",
  GET_FINANCE_GRAPH_DATA: "/finance",
  GET_ALL_PRESCRIPTION_FOR_FINANCE: "/dashboard/prescriptions/finance",
  GET_ALL_PRESCRIPTION: "/dashboard/prescription/all",
  UPDATE_PRESCRIPTION_STATUS: "/dashboard/prescriptions/update-status/",
  UPDATE_PRESCRIPTION_DETAILS: "/dashboard/prescriptions/",
  DISCOUNT_COMMISION_UPDATE: "/doctor/discount-commision-update",
  GET_ALL_DOCTOR: "/doctor/all",
  ADMIN_SEND_OTP: "/admin-send-otp",
  ADMIN_VERIFY_OTP: "/admin-verify-otp",
  GET_ALL_NOTIFICATION: "/notifications",
  GET_ALL_DOCTORS_NAMES: "/doctor/doctor-name-and-id",
  CREATE_PRESCRIPTION: "/prescription/create",
};
