// src/Router.tsx
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import {
  Dashboard,
  Doctor,
  DoctorDetails,
  Login,
  NotificationPage,
  Prescriptions,
  Register,
  ResetPassword,
  BillyRivera,
  Delivery,
  Financial,
  PrescriptionsDetails,
  NewDoctor,
  FinancialDoctors,
  CreatePrescription,
} from "./pages";
import store from "./redux/store";
import { Home } from "./pages/Home";

export enum AuthPages {
  LOGIN = "/login",
  REGISTER = "/register",
  RESETPASSWORD = "/reset-password",
}

export enum MainPages {
  ONBOARD = "/",
  Notification = "/notification",
  CREATE_PRESCRIPTION = "/new-prescription",
  Doctor = "/doctor",
  DoctorDetails = "/doctor/:id",
  Prescriptions = "/prescriptions",
  PrescriptionsDetails = "/prescriptions/:pid",
  Delivery = "/delivery",
  DELIVERY_BOY_DETAIL = "/delivery/:id",
  Financial = "/financial",
  NewDoctor = "/financial/new-doctor",
  FinancialDoctors = "/financial/doctor",
  FinancialDoctorDetails = "financial/doctor/:id",
  NewDoctorDetails = "financial/doctor/:id",
}

const isAuthenticated = (): boolean => {
  return store.getState().user.isSignedIn ?? false;
};

interface PrivateRouteProps {
  element: React.ReactElement;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to={AuthPages.LOGIN} />;
};

export const Router = () => {
  return (
    <BrowserRouter basename={MainPages.ONBOARD}>
      <Routes>
        <Route path={AuthPages.LOGIN} element={<Login />} />
        <Route path={AuthPages.RESETPASSWORD} element={<ResetPassword />} />
        <Route path={AuthPages.REGISTER} element={<Register />} />

        {/* Define Dashboard as a parent route */}
        <Route
          path={MainPages.ONBOARD}
          element={<PrivateRoute element={<Home />} />}
        >
          <Route index element={<Dashboard />} />
          <Route
            path={MainPages.CREATE_PRESCRIPTION}
            element={<CreatePrescription />}
          />
          <Route path={MainPages.Notification} element={<NotificationPage />} />
          <Route path={MainPages.Doctor} element={<Doctor />} />
          <Route
            path={MainPages.FinancialDoctors}
            element={<FinancialDoctors />}
          />
          <Route path={MainPages.NewDoctor} element={<NewDoctor />} />

          <Route path={MainPages.DoctorDetails} element={<DoctorDetails />} />
          <Route
            path={MainPages.NewDoctorDetails}
            element={<DoctorDetails />}
          />
          <Route path={MainPages.Prescriptions} element={<Prescriptions />} />
          <Route
            path={MainPages.PrescriptionsDetails}
            element={<PrescriptionsDetails />}
          />
          <Route path={MainPages.Delivery} element={<Delivery />} />
          <Route
            path={MainPages.DELIVERY_BOY_DETAIL}
            element={<BillyRivera />}
          />
          <Route path={MainPages.Financial} element={<Financial />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
