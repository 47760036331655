import { FC } from "react";

interface IPrescriptionCountCardProps {
  title: string;
  count: string;
  status: string;
}
export const PrescriptionCountCard: FC<IPrescriptionCountCardProps> = ({
  title,
  count,
  status,
}) => {
  const statusStyles: any = {
    open: {
      color: "bg-blue",
      bgColor: "bg-unnamed-a55cff29",
    },
    preparing: {
      color: "bg-yellow",
      bgColor: "bg-unnamed-ebbb1429",
    },
    declined: {
      color: "bg-red",
      bgColor: "bg-unnamed-f54e4e29",
    },
    dispatch: {
      color: "bg-unnamed-0BC1E5",
      bgColor: "bg-unnamed-0bc1e529",
    },
    delivered: {
      color: "bg-green",
      bgColor: "bg-unnamed-3fc27b29",
    },
    return: {
      color: "bg-unnamed-F54E8E",
      bgColor: "bg-unnamed-f54e8e29",
    },
    closed: {
      color: "bg-primary-blue",
      bgColor: "bg-unnamed-4a74f229",
    },
  };

  const { color } = statusStyles[status] || {
    color: "bg-gray-100",
  };

  return (
    <div
      className={`${color} bg-opacity-10 p-4 rounded-2xl flex flex-col justify-between gap-3`}
    >
      <div className="flex items-center gap-2">
        <span className={`w-4 h-4 rounded-full ${color}`}></span>
        <span className="text-primary-black text-base font-semibold capitalize">
          {title}
        </span>
      </div>
      <h2 className="text-4xl text-primary-black font-bold text-end">
        {count}
      </h2>
    </div>
  );
};
