import { FC } from "react";
import { RecentPrescription } from "../../components/dashboard/RecentPrescription";
import {
  LatestNotifications,
  RequiredDoctorDetails,
  TotalCount,
} from "../../components/dashboard";
import { useNavigate } from "react-router-dom";
import { MainPages } from "../../Router";

export const Dashboard: FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex-1 overflow-y-auto">
      <div className="p-4 flex flex-col gap-4">
        <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4">
          <div className="flex items-center justify-between">
            <h2 className="text-2xl font-semibold text-primary-black">
              Recent Prescriptions
            </h2>
            <button
              onClick={() => navigate(MainPages.Prescriptions)}
              className="text-dark-black text-sm font-bold underline underline-offset-1"
            >
              View All
            </button>
          </div>
          <RecentPrescription />
        </div>
        <div className="grid grid-cols-2 gap-4">
          {/* <RequiredDoctorDetails /> */}
          <TotalCount />
          <LatestNotifications />
        </div>
      </div>
    </div>
  );
};
