import secondaryMail from "./../../../images/secondary-mail.svg";
import secondaryCall from "./../../../images/secondary-call.svg";
import secondaryLocation from "./../../../images/secondary-location.svg";
import secondaryClinic from "./../../../images/secondary-clinic.svg";
import moment from "moment";
import { Avatar } from "../../Avatar";

export const DoctorDetailsCard = ({ doctor }: any) => {
  const address = {
    address: doctor?.Clinics[0]?.address,
    nearBy: doctor?.Clinics[0]?.near_by,
    city: doctor?.Clinics[0]?.city,
  };

  // Convert to an array, filter out undefined/null values, and join with a comma
  const formattedAddress = Object.values(address)
    .filter((value) => !!value)
    .join(", ");

  return (
    <div className="shadow-[0_0_16px_0_#171B1B14] rounded-2xl p-4 flex flex-col gap-6">
      <div className="flex justify-between">
        <h2 className="text-primary-black text-2xl font-semibold">
          Doctor Details
        </h2>
        <h4 className="text-dark-black text-sm font-normal">
          Joined on {moment(doctor?.createdAt).format("MMM D, YYYY")}
        </h4>
      </div>
      <div className="flex items-center gap-4">
        <div className="w-[54px] min-w-[54px] h-[54px] rounded-full overflow-hidden">
          {/* <img
            src={TheresaWebb}
            alt="TheresaWebb"
            className="w-full h-full object-cover"
          /> */}
          <Avatar
            src={doctor?.profileImage}
            name={`${doctor?.firstname} ${doctor?.lastname}`}
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col gap-1 flex-1">
          <span className="text-dark-black text-sm font-normal">
            Doctor Name
          </span>
          <h3 className="text-primary-black text-xl font-semibold">
            {`${doctor?.firstname} ${doctor?.lastname}`}
          </h3>
        </div>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex items-center gap-2">
          <div className="w-6 h-6 min-w-6 flex justify-center items-center">
            <img src={secondaryMail} alt="secondaryMail" />
          </div>
          <span className="text-base text-primary-black font-medium">
            {doctor?.email ?? "None"}
          </span>
        </div>
        <div className="flex items-center gap-2">
          <div className="w-6 h-6 min-w-6 flex justify-center items-center">
            <img src={secondaryCall} alt="secondaryCall" />
          </div>
          <span className="text-base text-primary-black font-medium">
            {doctor?.mobile ?? "None"}
          </span>
        </div>
        <div className="flex items-center gap-3 justify-between">
          <div className="flex items-center gap-2">
            <div className="w-6 h-6 min-w-6 flex justify-center items-center">
              <img src={secondaryClinic} alt="secondaryClinic" />
            </div>
            <span className="text-base text-primary-black font-medium">
              {!!doctor?.Clinics[0]?.name ? doctor?.Clinics[0]?.name : "None"}
            </span>
          </div>
          <div className="">
            <p className="text-base text-primary-black font-medium">
              Assistant:{" "}
              {doctor?.Clinics[0]?.assistant_name
                ? doctor?.Clinics[0]?.assistant_name
                : "None"}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-3 justify-between">
          <div className="flex items-center gap-2">
            <div className="w-6 h-6 min-w-6 flex justify-center items-center">
              <img src={secondaryLocation} alt="secondaryLocation" />
            </div>
            <span className="text-base text-primary-black font-medium">
              {!!formattedAddress ? formattedAddress : "None"}
            </span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-6 h-6 min-w-6 flex justify-center items-center">
              <img src={secondaryCall} alt="secondaryCall" />
            </div>
            <span className="text-base text-primary-black font-medium">
              {doctor?.Clinics[0]?.assistant_mobile
                ? doctor?.Clinics[0]?.assistant_mobile
                : "None"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
